import React from "react";
import Styles from "./FiguresCard.module.scss";

export interface FigureCard {
  asset: string;
  number: number;
  data: string;
  symbol: string;
}
export default function FiguresCards({
  asset,
  number,
  data,
  symbol,
}: FigureCard) {
  return (
    <div className={Styles.FiguresCardContainer}>
      <img src={asset} alt="" />
      <span>
        {number}
        {symbol}+
      </span>
      <span>{data}</span>
    </div>
  );
}
