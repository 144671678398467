import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// const resources = {
//     en: {
//         translation: {
//             welcome: "Welcome to React and react-i18next"
//         }
//     },
//     he: {
//         translation: {
//             hebrewTest: "בדיקההה"
//         }
//     }
// };

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      // path where resources get loaded from
      loadPath: "/locales/{{lng}}/{{ns}}.json",

      // path to post missing resources
      addPath: "/locales/add/{{lng}}/{{ns}}",

      // your backend server supports multiLoading
      // /locales/resources.json?lng=de+en&ns=translation
      // NOTICE: when set to false, the loadPath will have {{ns}} replaced too
      allowMultiLoading: false,

      // set loadPath: '/locales/resources.json' and multiLoading: true to adapt to multiLoading
      // queryStringParams: { v: '1.3.5' },

      // allow cross domain requests
      crossDomain: false,
    },
    fallbackLng: "en-US",
    debug: false,
    interpolation: {
      escapeValue: false, // react already saves from xss
    },
    supportedLngs: ["en-US", "he-IL", "tr-TR", "en-GB", "pt-BR", "es-ES", "de-DE"],
  });

export default i18n;
