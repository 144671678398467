export const WA_LINK = "https://wa.me/message/RSVKSSRNG22KK1";
export const chatLink = "https://chat.unichat.one/login"

const LOCAL_URI = "https://a46e-147-235-199-33.ngrok-free.app";
const DEV_URI = "https://newdev.unichat.one";
const PROD_URI = "https://app.unichat.one";
const apiUrl = process.env.REACT_APP_ENV

let environment: string;

if (window.location.href.includes("localhost")) {
  environment = "local";
} else if (window.location.href.includes("webdev")) {
  environment = "dev";
} else {
  environment = "prod";
}
export function isLocal() {
  return;
}

export function getURI() {

  // if (environment === "local") return LOCAL_URI;
  // if (environment === "dev") return DEV_URI;
  // if (environment === "prod") return PROD_URI;
  return apiUrl;
}

type LanguageStyles = {
  he: any;
  en: any;
};
export const buttonAndLabelStyles: LanguageStyles = {
  he: { alignItems: "unset", textAlign: "right", paddingLeft: "0", paddingRight: "10%" },
  en: { alignItems: "unset", textAlign: "unset", paddingLeft: "10%" }
}
export const buttonAndLabelStylesTwoButtons: LanguageStyles = {
  he: { marginRight: "7%" },
  en: {}
}
export const buttonAndLabelStylesWhatsAppSection: LanguageStyles = {
  he: { alignItems: "end", textAlign: "right", paddingLeft: "0", paddingRight: "20%" },
  en: { textAlign: "unset", paddingLeft: "20%" }
}

