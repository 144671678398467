import React from "react";
import styles from "./LpFeatureHero.module.scss";
import StartFreeButton from "../../StartFreeButton/StartFreeButton.cmp";
import { useTranslation } from "react-i18next";
import StartFreeAndImg from "../../../PageSections/StartFreeAndImg/StartFreeAndImg.cmp";

interface LpFeatureHeroProps {
  setOpenBillingModal: (open: boolean) => void;
}

const LpFeatureHero: React.FC<LpFeatureHeroProps> = ({
  setOpenBillingModal,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.lpFeatureHero}>
      <section className={styles.imageSection}>
        <div className={styles.purpleFrame}>
          <img
            src="https://unichatwebbapp.s3.amazonaws.com/Video%26Image.png"
            alt=""
          />
        </div>
      </section>
      <section className={styles.contentSection}>
        <div className={styles.textContentWrapper}>
          <div className={styles.textContent}>
            <span>Create</span> <span>Stunning </span> <span>Images</span>
          </div>
          <StartFreeButton
            title={t("StartFreeButton.StartFree")}
            customStyle={{ width: "200px", marginTop: "0" }}
            onClickMethod={() => setOpenBillingModal(true)}
          />
        </div>
      </section>

      {/* <div className={Styles.content}>
        <div className={Styles.textContent}>
          <h2 className={Styles.featureTitle}>{t("features")}</h2>
          <h1 className={Styles.mainTitle}>{t("videoImagePromptEnhancer")}</h1>
          <p className={Styles.description}>
            {t("createProfessionalQualityVideos")}
          </p>
          <StartFreeButton
            title={t("startFree")}
            customStyle={{ width: "200px" }}
          />
        </div>
        <div className={Styles.imageWrapper}>
          <img
            src="https://unichatwebbapp.s3.amazonaws.com/Video%26Image.png"
            alt="Video & Image Prompt Enhancer"
            className={Styles.featureImage}
          />
        </div>
      </div> */}
      {/* <StartFreeAndImg paragraphText="Create Stunning Images" imgName={["#"]} /> */}
    </div>
  );
};

export default LpFeatureHero;
