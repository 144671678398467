import React, { useState } from "react";
import styles from "./Shekel.module.scss";
import { useTranslation } from "react-i18next";

interface SectionProps {
  openModalFunction: () => void;
}

const Shekel = ({ openModalFunction }: SectionProps) => {
  const { t, i18n } = useTranslation();
  const [isLtr, setIsLtr] = useState(!i18n.language.includes("he"));

  return (
    <div
      className={`${styles.container} ${
        !i18n.language.includes("he") ? styles.containerLtr : ""
      } ${styles.shekelComponent}`}
    >
      <div className={styles.content}>
        {/* Box */}
        <div className={styles.box}>
          <h2 className={`${styles.boxHeading} ${styles.textCustomColor}`}>
            {t("LpPage.Shekel.firstDiv.firstH2")}
          </h2>
          <div className={styles.arrowContainer}>
            <img
              src="/assets/arrow-hero.svg"
              alt="arrow"
              className={styles.arrow}
            />
          </div>
        </div>

        {/* Content */}
        <div className={styles.textContent}>
          <h4 className={styles.textParagraph}>
            {t("LpPage.Shekel.secondDiv.firstH4")}
          </h4>
          <h4 className={styles.textParagraph}>
            {t("LpPage.Shekel.secondDiv.secondH4")}
          </h4>
          <h4 className={styles.textParagraph}>
            {t("LpPage.Shekel.secondDiv.thirdH4")}
          </h4>
          <h4 className={styles.textParagraph}>
            {t("LpPage.Shekel.secondDiv.fourthH4")}
          </h4>
          {!isLtr && (
            <div className={styles.videoContainer}>
              <video
                className={styles.video}
                width="100%"
                autoPlay
                playsInline
                loop
                preload="auto"
                // controls
                ref={(videoElement) => {
                  if (videoElement) {
                    videoElement.muted = true;
                  }
                }}
                onClick={(e) => {
                  e.preventDefault();
                  const video = e.target as HTMLVideoElement;
                  video.muted = !video.muted;
                }}
              >
                <source
                  src="https://unichatwebbapp.s3.amazonaws.com/unichat-vid.webm"
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
        <div className={styles.largeTextContent}>
          <h5 className={styles.subheading}>
            {t("LpPage.Shekel.thirdDiv.firstH5")}
          </h5>
          <h4 className={styles.largeTextParagraph}>
            {t("LpPage.Shekel.thirdDiv.firstH4")}
          </h4>
          <h4 className={styles.largeTextParagraph}>
            {t("LpPage.Shekel.thirdDiv.secondH4")}
          </h4>
        </div>

        {/* Box */}
        <div className={styles.dashedBox}>
          <h2 className={styles.dashedBoxHeading}>
            {t("LpPage.Shekel.forthDiv.firstH2")}
          </h2>
          <div className={styles.buttonContainer}>
            <div className={styles.gradientButton} onClick={openModalFunction}>
              <button className={styles.buttonText}>
                {t("LpPage.FreeTrialButton.button")}
              </button>
              <div className={styles.daysCounter}>
                <h2 className={styles.daysNumber}>
                  {t("LpPage.FreeTrialButton.number")}
                </h2>
                <h4 className={styles.daysText}>
                  {t("LpPage.FreeTrialButton.days")}
                </h4>
              </div>
            </div>
          </div>
          <h4 className={styles.dashedBoxSubheading}>
            {t("LpPage.Shekel.forthDiv.firstH4")}
          </h4>
          <button className={styles.startButton} onClick={openModalFunction}>
            {t("LpPage.Shekel.forthDiv.button")}
          </button>
          <p className={styles.dashedBoxParagraph}>
            {t("LpPage.Shekel.forthDiv.p")}
          </p>
        </div>
        <div className={styles.secretSection}>
          <h2 className={styles.secretHeading}>
            {t("LpPage.Shekel.fifthDiv.firstH2")}
          </h2>
          <h4 className={styles.secretSubheading}>
            {t("LpPage.Shekel.fifthDiv.firstH4")}
          </h4>
        </div>
        <div className={styles.imageGrid}>
          <img
            src={
              isLtr
                ? `/assets/no-secret-one-eng.png`
                : `/assets/no-secret-one.svg`
            }
            alt="Its no secret"
            className={styles.gridImage}
          />
          <img
            src={
              isLtr
                ? `/assets/no-secret-two-eng.png`
                : `/assets/no-secret-two.svg`
            }
            alt="Its no secret"
            className={styles.gridImage}
          />
          <img
            src={
              isLtr
                ? `/assets/no-secret-three-eng.png`
                : `/assets/no-secret-three.svg`
            }
            alt="Its no secret"
            className={styles.gridImage}
          />
          <img
            src={
              isLtr
                ? `/assets/no-secret-four-eng.png`
                : `/assets/no-secret-four.svg`
            }
            alt="Its no secret"
            className={styles.gridImage}
          />
        </div>
        <div className={styles.finalSection}>
          <h4 className={`${styles.finalParagraph} ${styles.h4FianlParagraph}`}>
            {t("LpPage.Shekel.sixthDiv.firstH4")}
          </h4>
          <h4 className={styles.highlightedParagraph}>
            {t("LpPage.Shekel.sixthDiv.secondH4")}
          </h4>
          <p className={styles.finalParagraph}>
            {t("LpPage.Shekel.sixthDiv.p")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Shekel;
