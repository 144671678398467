import React, { useState } from "react";
import styles from "./WhatsappRegistration.module.scss";
import InsertWhatsAppNumber from "../InsertWhatsAppNumber/InsertWhatsAppNumber.cmp";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { languageModel } from "../../store/atoms/generalAtoms";
import { clientPhoneNumber } from "../../store/atoms/contactUsAtom";
import PhoneInput, {
  isValidPhoneNumber,
} from "react-phone-number-input/mobile";
import { Button, CircularProgress } from "@mui/material";
import { getAllUTMData, storage } from "../../utils/utils";
import { userVerification } from "../../utils/service";
import OTPModal from "../OTPModal/OTPModal.cmp";
import whatsAppLogo from "../../assets/whiteWhatsApp.png";
import { EmailOutlined } from "@mui/icons-material";
import {
  moneyBackGuarantee,
  moneyBackGuaranteePurple,
} from "../../utils/assetsImports";

interface WhatsappRegistrationProps {
  setStep: (number: number) => void;
  setRegistered: React.Dispatch<React.SetStateAction<boolean>>;
  isMoneyBackGuarantee?: boolean;
}

type languageCode = "IL" | "US" | "GB" | "TR";

const WhatsappRegistration = ({
  setStep,
  setRegistered,
  isMoneyBackGuarantee,
}: WhatsappRegistrationProps) => {
  const recoilLangModel = useRecoilValue(languageModel);
  const [t, i18n] = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useRecoilState<any>(clientPhoneNumber);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [userToken, setUserToken] = useState<string>("");
  const [openOTPModal, setOpenOTPModal] = useState<boolean>(false);

  const sendPhoneNumberValidate = async () => {
    setIsLoading(true);
    if (!sendDataValidate()) {
      setIsLoading(false);
      return;
    }

    try {
      const utmData = getAllUTMData(phoneNumber, "whatsApp");
      const userPhone = phoneNumber.slice(1);
      const response = await userVerification.phoneNumberSignIn(
        userPhone,
        utmData,
        i18n.language
      );

      storage.saveToStorage("userId", response.userId);
      handleReponseAndOpenOTP(response);
      storage.saveToStorage("userPhone", userPhone);
    } catch (err) {
      setIsLoading(false);
      setErrorMessage("Error login");
    }
  };

  const sendDataValidate = (): boolean => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      if (errorMessage !== "") {
        setErrorMessage("");
      }
      return true;
    }
    setErrorMessage(t("LoginPage.HelperErrorMessage.WhatsApp"));
    return false;
  };

  const sendEmailValidate = async () => {
    try {
      setIsLoading(true);
      if (!validateEmail()) {
        setIsLoading(false);
        throw new Error("Error email");
      }
      const utmData = getAllUTMData(email?.toLocaleLowerCase()!, "email");
      const client_id = storage.loadFromStorage("clientId");
      const userEmail = email;
      const response = await userVerification.emailSignIn(
        email,
        utmData,
        i18n.language,
        client_id
      );

      // storage.saveToStorage("userId", response.userId);
      handleReponseAndOpenOTP(response);
      storage.saveToStorage("userEmail", userEmail);
    } catch (err) {
      setIsLoading(false);
      setErrorMessage("Error login");
    }
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateEmail = (): boolean => {
    if (email && isValidEmail(email)) {
      if (errorMessage !== "") {
        setErrorMessage("");
      }
      return true;
    }
    setErrorMessage(t("LoginPage.HelperErrorMessage.Email"));
    return false;
  };

  const handleReponseAndOpenOTP = (response: any) => {
    const clientId = storage.loadFromStorage("clientId");
    setUserToken(response.token);
    storage.saveToStorage("userId", response.userId);
    (window as any).dataLayer = (window as any).dataLayer || [];
    if (response.newUser) {
      (window as any).dataLayer.push({
        event: "start_sign_up",
        user_id: response.userId,
        user_email: email,
        user_phone: phoneNumber,
      });
    }
    (window as any).dataLayer.push({
      event: "client_identification",
      client_id: clientId,
    });

    setOpenOTPModal(true);
    setIsLoading(false);
    setErrorMessage("");
    // response.newUser && setIsNewUser(true)
  };

  return (
    <div className={styles.whatsAppRegistrationMainContainer}>
      <section className={styles.whatsAppRegistrationHeader}>
        {isMoneyBackGuarantee ? (
          <img
            src={moneyBackGuaranteePurple}
            alt="money back guarantee"
            height={150}
            width={150}
          />
        ) : (
          <>
            <span>7</span>
            <h1>{t("whatsappRegistration.title")}</h1>
            {/* <p>הכרטיס לא יחויב לפני תום תקופת הניסיון</p> */}
          </>
        )}
      </section>
      <hr />
      <section className={styles.whatsAppRegistrationSecondaryContainer}>
        <h3>{t("whatsappRegistration.secondTitle")}</h3>
        <form
        // onSubmit={(e) => {
        //   console.log("Form submitted");
        //   e.preventDefault();
        //   sendEmailValidate();
        // }}
        >
          <div className={styles.emailInputContainer}>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className={styles.emailInput}
              placeholder={t("whatsappRegistration.placeholder")}
            />
            {emailError && (
              <span
                className={`${styles.helperText} ${styles.errorMessage}`}
                style={{
                  visibility: emailError === " " ? "hidden" : "visible",
                  fontSize: "0.75rem",
                  alignSelf: "flex-end",
                  margin: "3px 0 0 14px",
                }}
              >
                {emailError === " " ? "error" : emailError}
              </span>
            )}
          </div>
          {/* <PhoneInput
            defaultCountry={
              // recoilLangModel.toLocaleLowerCase().includes("he") ? "IL" : "US"
              i18n.language.split("-")[1]
                ? (i18n.language.split("-")[1] as languageCode)
                : "US"
            }
            placeholder={t("whatsappRegistration.placeholder")}
            value={phoneNumber}
            withCountryCallingCode
            international
            onChange={setPhoneNumber}
            rules={{ required: true }}
            className={styles.PhoneInput}
          /> */}
          {errorMessage && (
            <span
              className={`${styles.helperText} ${styles.errorMessage}`}
              style={{
                visibility: errorMessage === " " ? "hidden" : "visible",
                fontSize: "0.75rem",
                alignSelf: "flex-end",
                margin: "3px 0 0 14px",
              }}
            >
              {errorMessage === " " ? "error" : errorMessage}
            </span>
          )}
          <Button
            className={styles.continueButton}
            type="submit"
            onClick={sendEmailValidate}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <div>
                <span>{t("whatsappRegistration.registrationButton")}</span>
                {/* <img
                  src={whatsAppLogo}
                  alt="whatsapp logo"
                  height={30}
                  width={30}
                /> */}
                <EmailOutlined height={20} width={20} />
              </div>
            )}
          </Button>
        </form>
        <p className={styles.agreement}>
          {t("whatsappRegistration.agreement")}{" "}
          <a
            href="/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              window.open("/terms-of-use");
            }}
          >
            {t("whatsappRegistration.termsOfService")}{" "}
          </a>
          {t("whatsappRegistration.and")}{" "}
          <a
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              window.open("/privacy-policy");
            }}
          >
            {t("whatsappRegistration.privacyPolicy")}
          </a>
        </p>
        <p className={styles.existingUser}>
          {t("whatsappRegistration.existingUser")}{" "}
          <a
            href="https://chat.unichat.one"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              window.open("https://chat.unichat.one");
            }}
          >
            {t("whatsappRegistration.loginHere")}
          </a>
        </p>
      </section>
      {openOTPModal && (
        <OTPModal
          closeModal={setOpenOTPModal}
          setStep={setStep}
          setRegistered={setRegistered}
          userToken={userToken}
          // isWhatsapp
        />
      )}
    </div>
  );
};

export default WhatsappRegistration;
