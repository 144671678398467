import React, { useState, useEffect } from "react";
import styles from "./LpVideoSection.module.scss";
import { useTranslation } from "react-i18next";

const Video = () => {
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const video = document.querySelector("video");
    if (video) {
      video.addEventListener("loadeddata", () => setIsLoading(false));
    }
    return () => {
      if (video) {
        video.removeEventListener("loadeddata", () => setIsLoading(false));
      }
    };
  }, []);

  return (
    <div className={styles.videoContainer}>
      {isLoading && (
        <div className={styles.loader}>
          <div className={styles.spinner}></div>
        </div>
      )}
      <video
        className={`${styles.videoPlayer} ${isLoading ? styles.hidden : ""}`}
        autoPlay
        playsInline
        muted
        loop
      >
        <source
          src={
            i18n.language.includes("he")
              ? "https://unichatwebbapp.s3.amazonaws.com/video-ai.mp4"
              : "https://unichatwebbapp.s3.amazonaws.com/IMG_0034.MP4"
          }
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
