import React from "react";
import { m, LazyMotion, domMax } from "framer-motion";

export const LazyMotionWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // const loadFeatures = () => import('./features').then(res => res.default);
  return (
    <LazyMotion features={domMax} strict>
      {children}
    </LazyMotion>
  );
};
