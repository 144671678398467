import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import Divider from "@mui/material/Divider";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ChatIcon from "@mui/icons-material/Chat";
import InfoIcon from "@mui/icons-material/Info";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha("#ffffff", theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function CustomizedMenus() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  // function that checks if the currently clicked redirection button is the same page that the client is currently on, if so it scrolls to top, if not it redirects him.
  function navigationOrScroll(title: string) {

    const endPointUrl: string | undefined = `/${window.location.href.split('/').at(-1)}`
    if (endPointUrl === title) {
      const mainSectionDiv: HTMLElement | null = document.getElementById(
        `${title}`

      );
      if (mainSectionDiv) {
        endPointUrl === title ? mainSectionDiv.scrollTo({ top: 0, behavior: 'smooth' }) : navigate(title)

      }
    } else {
      navigate(title)

    }
    handleClose()
  }

  // function that scrolls the chosen component into view
  function scrollToSection(title: string) {
    const mainSectionDiv: HTMLElement | null = document.getElementById(
      `${title}`
    );
    if (mainSectionDiv) {
      mainSectionDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  // sends the current menu item data-name to the scroll to section function and scrolls there.
  function onClick(event: React.MouseEvent) {
    const itemClicked = event.currentTarget.getAttribute("data-name");

    if (itemClicked) {
      scrollToSection(itemClicked)
    }
    setTimeout(handleClose, 700);
  }


  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        style={{ backgroundColor: "transparent" }}
      // endIcon={<KeyboardArrowDownIcon />}
      >
        {/* <div
          className={Styles.HamburgerLines}
        //   onClick={() => setOpenAndCloseState(!openAndCloseFlag)}
        >
          <span className={Styles.Line}></span>
          <span className={Styles.Line}></span>
          <span className={Styles.Line}></span>
        </div> */}
        <MenuRoundedIcon fontSize="large" />
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem data-name="midjourney" title="/Midjourney" onClick={(e) => navigationOrScroll((e.target as HTMLElement).title)} disableRipple>
          <BurstModeIcon />
          {t('MuiMenu.Midjourney')}
        </MenuItem>
        {/* <MenuItem data-name="GPT4" onClick={onClick} disableRipple>
          <ChatIcon />
          GPT-4
        </MenuItem> */}
        {/* <MenuItem data-name="about" onClick={onClick} disableRipple>
          <InfoIcon />
          About
        </MenuItem> */}
        <MenuItem data-name="pricing" title="/pricing" onClick={(e) => navigationOrScroll((e.target as HTMLElement).title)} disableRipple>
          <LocalOfferIcon />
          {t('MuiMenu.Pricing')}
        </MenuItem>
        {/* <Divider sx={{ my: 0.5 }} /> */}
        <MenuItem data-name="faq" onClick={onClick} disableRipple>
          <LiveHelpIcon />
          {t('MuiMenu.FAQ')}
        </MenuItem>
        <MenuItem data-name="contact" onClick={onClick} disableRipple>
          <AlternateEmailIcon />
          {t('MuiMenu.Contact')}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
