import { atom } from "recoil";

// global state that responsible for the opening and closing of lets chat modal
export const openLetsChatModal = atom({
    key: "openLetsChatModal",
    default: false
})

// global state that stores the user phone number
export const clientPhoneNumber = atom({
    key: "clientPhoneNumber",
    default: ""
})