import React from "react";
import styles from "./ServicesCompare.module.scss";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { languageModel } from "../../store/atoms/generalAtoms";

const ServicesCompare = () => {
  const [t, i18n] = useTranslation();
  const recoilLangModel = useRecoilValue(languageModel);

  return (
    <div
      className={`${styles.servicesCompareContainer} `}
      style={recoilLangModel.includes("he") ? { direction: "rtl" } : {}}
    >
      <div className={styles.seperatedAIsTitle}>
        <span>
          <span>{t("ServiceCompare.SeparatedAI.title1")}</span>
          <span>{t("ServiceCompare.SeparatedAI.title2")}</span>
        </span>
      </div>
      <div className={styles.unichatTitle}>
        <span>{t("ServiceCompare.Unichat")}</span>
      </div>

      <div className={styles.servicesName}>
        <span>{t("ServiceCompare.ServiceName.Service1")}</span>
        <span>{t("ServiceCompare.ServiceName.Service2")}</span>
        {/* <span>{t("ServiceCompare.ServiceName.Service3")}</span> */}
        <span>{t("ServiceCompare.ServiceName.Service4")}</span>
        {/* <span>{t("ServiceCompare.ServiceName.Service5")}</span> */}
        <span>{t("ServiceCompare.ServiceName.Service6")}</span>
      </div>
      <div className={styles.servicesPrices}>
        <span>{t("ServiceCompare.ServicePrices.price1")}</span>
        <span>{t("ServiceCompare.ServicePrices.price2")}</span>
        {/* <span>{t("ServiceCompare.ServicePrices.price3")}</span> */}
        <span>{t("ServiceCompare.ServicePrices.price4")}</span>
        {/* <span>{t("ServiceCompare.ServicePrices.price5")}</span> */}
        <span>{t("ServiceCompare.ServicePrices.price6")}</span>
      </div>
      <div className={styles.included}>
        <span>{t("ServiceCompare.Included")}</span>
        <span>{t("ServiceCompare.Included")}</span>
        {/* <span>{t("ServiceCompare.Included")}</span> */}
        <span>{t("ServiceCompare.Included")}</span>
        {/* <span>{t("ServiceCompare.Included")}</span> */}
        <span>{t("ServiceCompare.Included")}</span>
      </div>

      <span className={styles.startingTotal}>
        {t("ServiceCompare.StartingTotal")}
      </span>
      <span className={styles.multiAIPrice}>
        {t("ServiceCompare.MultiAiTotal")}
      </span>
      <span className={styles.unichatPrice}>
        {t("ServiceCompare.UnichatTotal")}
      </span>
    </div>
  );
};

export default ServicesCompare;
