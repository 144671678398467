import React, { useState } from "react";
import Styles from "./FaqSection.module.scss";
import AboutAccordion, {
  UniAccordion,
} from "../../cmps/AboutAccordion/AboutAccordion.cmp";
import {
  getAccordionsData,
  getFreeAccordionsData,
} from "../../utils/HardCodedData";
import { useTranslation } from "react-i18next";

interface FaqSectionProps {
  accodrionData?: boolean;
  notFreePage?: boolean;
}

export default function FaqSection({
  accodrionData,
  notFreePage,
}: FaqSectionProps) {
  const [aboutAccordionsData, setAboutAccordionsData] = useState<
    UniAccordion[]
  >(
    accodrionData
      ? getFreeAccordionsData()
      : notFreePage
      ? getAccordionsData().filter((_, index) => index !== 1)
      : getAccordionsData()
  );
  const [t, i18n] = useTranslation();
  return (
    <div id="faq" className={Styles.FaqSectionMainContainer}>
      <p>{t("FaqSection.p")}</p>
      <AboutAccordion accordionsArr={aboutAccordionsData} />
    </div>
  );
}
