import React, { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./UniNavBar.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedMenus from "../CustomizedMenu/CustomizedMenu";
import { log } from "console";
import { useTranslation } from "react-i18next";
import LangSelector from "../../utils/UtilCmps/LangSelector/LangSelector.cmp";
import UnichatNewLogo from "../../assets/UnichatNewLogo.svg";
import { unichatWebSiteNewLogo } from "../../utils/assetsImports";

export default function UniNavBar() {
  const location = useLocation();
  const [t, i18n] = useTranslation();
  const [navBarTabs, setNavBarTabs] = useState<any[]>([
    <span key={`home`} onClick={() => navigationOrScroll("/")}>
      {t("UniNavBar.Main")}
    </span>,
    // "home",
    <span key={`Midjourney`} onClick={() => navigationOrScroll("/Midjourney")}>
      {t("UniNavBar.Midjourney")}
    </span>,
    // <span key={`gallery`} onClick={() => navigationOrScroll("/Gallery")}>
    //   GPT-4
    // </span>,
    // "service+",
    // "blog",
    <span
      key={`pricing`}
      title="pricing"
      onClick={() => navigationOrScroll("/pricing")}
    >
      {t("UniNavBar.Pricing")}
    </span>,
    <span
      key={`faq`}
      title="faq"
      onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
        scrollToSection((e.target as HTMLSpanElement).title)
      }
    >
      {t("UniNavBar.About")}
    </span>,
    <span
      key={`contact`}
      title="contact"
      onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
        scrollToSection((e.target as HTMLSpanElement).title)
      }
    >
      {t("UniNavBar.Contact")}
    </span>,
  ]);

  const [openSearchBar, setOpenSearchBar] = useState<boolean>(false);
  const searchBarInput = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [headerLinksVisible, setHeaderLinksVisible] = useState<boolean>(false);
  const [lpPage, setLpPage] = useState<boolean>(false);

  useEffect(() => {
    // const pathIncludes = !(location.pathname.toLocaleLowerCase().includes('/free') || location.pathname.toLocaleLowerCase().includes('/whatsapp'))
    const pathIncludes = !location.pathname
      .toLocaleLowerCase()
      .includes("/free");
    const headerLinkPathIncludes = !location.pathname
      .toLocaleLowerCase()
      .includes("/whatsapp");
    // console.log("🚀 ~ file: UniNavBar.cmp.tsx:42 ~ useEffect ~ pathIncludes:", pathIncludes)
    // Set visibility to false if the URL contains '/free'
    setHeaderLinksVisible(headerLinkPathIncludes);
    setIsVisible(pathIncludes);

    if (location.pathname.toLocaleLowerCase().includes("/lp")) {
      setLpPage(true);
      // setIsVisible(false);
      setNavBarTabs([
        <span
          key={"/terms-of-use"}
          title="terms-of-use"
          onClick={() => navigationOrScroll("/terms-of-use")}
        >
          {t("LoginPage.TermsOfUse")}
        </span>,
        <span
          key={"/privacy-policy"}
          title="privacy-policy"
          onClick={() => navigationOrScroll("/privacy-policy")}
        >
          {t("LoginPage.PrivacyPolicy")}
        </span>,
      ]);
    }
  }, [location]);

  function navigationOrScroll(title: string) {
    const endPointUrl: string | undefined = `/${window.location.href
      .split("/")
      .at(-1)}`;
    if (endPointUrl === title) {
      const mainSectionDiv: HTMLElement | null = document.getElementById(
        `${title}`
      );
      if (mainSectionDiv) {
        endPointUrl === title
          ? mainSectionDiv.scrollTo({ top: 0, behavior: "smooth" })
          : navigate(title);
      }
    } else {
      navigate(title);
    }
  }
  function scrollToSection(title: string) {
    const mainSectionDiv: HTMLElement | null = document.getElementById(
      `${title}`
    );
    // console.log(mainSectionDiv)
    if (mainSectionDiv) {
      mainSectionDiv.scrollIntoView({ behavior: "smooth", block: "start" });
      // setTimeout(() => window.scrollBy(0, 0));
    }
  }

  // function scrollToContact() {
  //   const mainSectionDiv: HTMLElement | null = document.getElementById(
  //     "uniContact-main-container"
  //   );
  //   if (mainSectionDiv) {
  //     mainSectionDiv.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // }

  // UseEffect That listens for mouse click on the search input
  // useEffect(() => {
  //   const handleClickOutsideSearchInput = (event: { target: any }) => {
  //     if (
  //       searchBarInput.current &&
  //       !searchBarInput.current.contains(event.target)
  //     ) {
  //       setOpenSearchBar(false);
  //     }
  //   };
  //   window.addEventListener("mousedown", handleClickOutsideSearchInput);

  //   return () => {
  //     window.removeEventListener("mousedown", handleClickOutsideSearchInput);
  //   };
  // }, []);

  return (
    <div id="navbar" className={Styles.UniNavBarContainer}>
      <span className={Styles.UniLogoAndLabel}>
        <img src={unichatWebSiteNewLogo} alt="unichat logo" />
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (!lpPage) {
              navigationOrScroll("/");
            }
          }}
        >
          UNICHAT
        </span>
      </span>
      {headerLinksVisible && (
        <span
          className={Styles.UniNavBarTabs}
          style={{
            flexDirection: i18n.language.includes("he") ? "row-reverse" : "row",
          }}
        >
          {navBarTabs.map((tab: string, index: number) => {
            return <span key={`${tab}_${index}`}>{tab}</span>;
          })}
        </span>
      )}
      {/* <span className={Styles.UniNavBarUtils}> */}
      {/* <input
          ref={searchBarInput}
          type="text"
          placeholder="SEARCH"
          className={`${Styles.UniNavBarSearchBar} ${openSearchBar ? Styles.OpenSearchBar : ""
            }`}
        /> */}
      {/* <span
          className={`${Styles.SearchButton} ${
            openSearchBar ? Styles.Hidden : ""
          }`}
          onClick={() => setOpenSearchBar(!openSearchBar)}
        >
          <img src={require("../../assets/Search.png")} alt="" />
        </span> */}
      <span
        className={Styles.UniNavBarMenu}
        id="lang-selector"
        style={{ visibility: isVisible ? "visible" : "hidden" }}
      >
        <LangSelector />
        <CustomizedMenus />
      </span>
      {/* <span>
          <img src={require("../../assets/Dark.png")} alt="" />
        </span> */}
      {/* </span> */}
    </div>
  );
}
