import React, { useEffect, useLayoutEffect, useState } from "react";
import logo from "./logo.svg";
// Styles
import "./App.css";
import UniGalleryPage from "./pages/UniGalleryPage/UniGalleryPage.cmp";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import UniNavBar from "./cmps/UniNavBar/UniNavBar.cmp";
import UniHomePage from "./pages/UniHomePage/UniHomePage.cmp";
import MidJourneyPage from "./pages/MidJourneyPage/MidJourneyPage.cmp";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { openLetsChatModal } from "./store/atoms/contactUsAtom";
import LetsChatModal from "./cmps/LetsChatModal/LetsChatModal.cmp";
import UniFooter from "./cmps/UniFooter/UniFooter.cmp";
import UniContact from "./cmps/UniContact/UniContact.cmp";
import PricingPage from "./pages/PricingPage/PricingPage.cmp";
import i18n from "./utils/i18n";
import {
  isP0FreeRegion,
  languageModel,
  openActivePremium0Modal,
  redirectedToThankYouPage,
} from "./store/atoms/generalAtoms";
import { extractUserData, storage } from "./utils/utils";
import LoginPage from "./pages/LoginPage/LoginPage.cmp";
import ThankYouPage from "./pages/ThankYouPage/ThankYouPage.cmp";
import FreePage from "./pages/FreePage/FreePage.cmp";
import WhatsAppPage from "./pages/WhatsAppPage/WhatsAppPage.cmp";
import TestPage from "./pages/TestPage/TestPage.cmp";
import ChatPricingPage from "./pages/ChatPricingPage/ChatPricingPage.cmp";
import { userVerification } from "./utils/service";
import FreeRegionModal from "./cmps/FreeRegionModal/FreeRegionModal.cmp";
import PrivacyAndTOSPage from "./pages/PrivacyAndTOSPage/PrivacyAndTOSPage.cmp";
import {
  tosData,
  privacyPolicyData,
  privacyPolicyDataHeb,
  tosDataHeb,
} from "../src/utils/HardCodedData";
import Payment from "./pages/Admin/Payment/Payment.cmp";
import ExtensionThankYou from "./pages/ExtensionThankYou/ExtensionThankYou.cmp";
import Lp from "./pages/Lp/Lp";
import WhatsAppOldPage from "./pages/WhatsappOldPage/WhatsappOldPage.cmp";
import ChatPage from "./pages/ChatPage/ChatPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import FreeTrialPage from "./pages/FreeTrialPage/FreeTrialPage";
import WhatsAppLp from "./pages/WhatsAppLp/WhatsAppLp.page";
import { nanoid } from "nanoid";

const supportedLanguages: { [key: string]: string } = {
  en: "US",
  he: "IL",
  tr: "TR",
  pt: "BR",
  es: "ES",
};

function App() {
  const navigate = useNavigate();
  const openChatModal = useRecoilValue(openLetsChatModal);
  const [recoilLangModel, setRecoilLangModel] = useRecoilState(languageModel);
  const [thankYouPage, setThankYouPage] = useRecoilState<boolean>(
    redirectedToThankYouPage
  );
  // const setIsFreeRegion = useSetRecoilState<boolean>(isP0FreeRegion);
  // const [isP0ModalOpen, setIsP0ModalOpen] = useRecoilState<boolean>(
  //   openActivePremium0Modal
  // );
  // const [showNavBar, setShowNavBar] = useState<boolean>(false);
  // const location = useLocation();

  const changeLang = async () => {
    const urlLang = extractUserData.extractUrlLanguage();
    if (urlLang.lang) {
      // console.log("🚀 ~ file: App.tsx:26 ~ changeLang ~ urlLang:", urlLang);
      await i18n.changeLanguage(urlLang.lang);
      setRecoilLangModel(urlLang.lang);
    } else {
      const lng = navigator.language;
      let formattedLng = lng;

      if (lng.length === 2) {
        const country = supportedLanguages[lng];
        if (country) {
          formattedLng = `${lng}-${country}`;
        } else {
          // If not in our supported list, default to en-US
          formattedLng = "en-US";
        }
      } else if (
        !["en-US", "he-IL", "tr-TR", "en-GB", "pt-BR"].includes(formattedLng)
      ) {
        // If it's already a long code but not in our supported list, default to en-US
        formattedLng = "en-US";
      }

      await i18n.changeLanguage(formattedLng);
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set("lang", formattedLng);
      window.history.replaceState({}, "", currentUrl.toString());
      setRecoilLangModel(formattedLng);
    }
  };

  useLayoutEffect(() => {
    changeLang();
    const clientId = storage.loadFromStorage("clientId");
    if (!clientId) {
      storage.saveToStorage("clientId", nanoid());
    }
    // });
  }, []);

  // useEffect(() => {
  //   if (!location.pathname.includes("/login")) {
  //     setShowNavBar(true);
  //   } else {
  //     setShowNavBar(false);
  //   }
  // }, [location])

  return (
    <div
      className={`App ${
        recoilLangModel.toLocaleLowerCase().includes("he") ? "HebFont" : ""
      }`}
      key={i18n.language}
    >
      {!thankYouPage && <UniNavBar />}
      <Routes>
        <Route path="/" element={<UniHomePage />} />
        <Route path="/MidJourney" element={<MidJourneyPage />} />
        {/* <Route path="/Gallery" element={<UniGalleryPage />} /> */}
        <Route path="/Pricing" element={<PricingPage />} />
        <Route path="/thank-you-page" element={<ThankYouPage />} />
        <Route path="/exttnku" element={<ExtensionThankYou />} />
        {/* <Route path="/Free" element={<FreePage />} /> */}
        <Route path="/WhatsApp" element={<WhatsAppOldPage />} />
        <Route path="/WhatsAppFreeTrial" element={<WhatsAppPage />} />
        <Route path="/whatsapplp" element={<WhatsAppLp />} />
        <Route path="/freetrial" element={<FreeTrialPage />} />
        <Route path="/landingpage" element={<LandingPage />} />
        <Route path="/chatplans" element={<ChatPricingPage />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route
          path="/privacy-policy"
          element={
            <PrivacyAndTOSPage
              data={
                recoilLangModel.includes("he")
                  ? privacyPolicyDataHeb
                  : privacyPolicyData
              }
            />
          }
        />
        <Route
          path="/terms-of-use"
          element={
            <PrivacyAndTOSPage
              data={recoilLangModel.includes("he") ? tosDataHeb : tosData}
            />
          }
        />
        <Route path="/admin/payment" element={<Payment />} />
        <Route path="/lp" element={<Lp />} />
        {/* <Route path="/test" element={<TestPage />} /> */}
        {/* <Route path="/login" element={<LoginPage />} /> */}
      </Routes>
      {/* <UniFooter /> */}
      {/* </div> */}
      {openChatModal && <LetsChatModal />}
      {/* {isP0ModalOpen && <FreeRegionModal />} */}
    </div>
  );
}

export default App;
