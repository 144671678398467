import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Unichat.module.scss";

interface SectionProps {
  openModalFunction: () => void;
}

const Unichat = ({ openModalFunction }: SectionProps) => {
  const { t, i18n } = useTranslation();

  // Update this line to use the current language
  const isLtr = !i18n.language.includes("he");

  const benefits = [
    {
      id: 1,
      title: t("LpPage.unichat.benefits.1.title"),
      description: t("LpPage.unichat.benefits.1.description"),
      imgSrc: "/assets/1.gif",
    },
    {
      id: 2,
      title: t("LpPage.unichat.benefits.2.title"),
      description: t("LpPage.unichat.benefits.2.description"),
      imgSrc: "/assets/2.gif",
    },
    {
      id: 3,
      title: t("LpPage.unichat.benefits.3.title"),
      description: t("LpPage.unichat.benefits.3.description"),
      imgSrc: "/assets/3.gif",
    },
    {
      id: 4,
      title: t("LpPage.unichat.benefits.4.title"),
      description: t("LpPage.unichat.benefits.4.description"),
      imgSrc: "/assets/4.gif",
    },
    {
      id: 5,
      title: t("LpPage.unichat.benefits.5.title"),
      description: t("LpPage.unichat.benefits.5.description"),
      imgSrc: "/assets/5.gif",
    },
    {
      id: 6,
      title: t("LpPage.unichat.benefits.6.title"),
      description: t("LpPage.unichat.benefits.6.description"),
      imgSrc: "/assets/6.gif",
    },
    {
      id: 7,
      title: t("LpPage.unichat.benefits.7.title"),
      description: t("LpPage.unichat.benefits.7.description"),
      imgSrc: "/assets/7.gif",
    },
    {
      id: 8,
      title: t("LpPage.unichat.benefits.8.title"),
      description: t("LpPage.unichat.benefits.8.description"),
      imgSrc: "/assets/8.gif",
    },
    {
      id: 9,
      title: t("LpPage.unichat.benefits.9.title"),
      description: t("LpPage.unichat.benefits.9.description"),
      imgSrc: "/assets/9.gif",
    },
    {
      id: 10,
      title: t("LpPage.unichat.benefits.10.title"),
      description: t("LpPage.unichat.benefits.10.description"),
      imgSrc: "/assets/10.gif",
    },
  ];

  const benefitsbouns = [
    {
      id: 11,
      title: t("LpPage.unichat.benefitsbouns.11.title"),
      description: t("LpPage.unichat.benefitsbouns.11.description"),
      imgSrc: "/assets/11.gif",
    },
    {
      id: 12,
      title: t("LpPage.unichat.benefitsbouns.12.title"),
      description: t("LpPage.unichat.benefitsbouns.12.description"),
      imgSrc: "/assets/12.gif",
    },
  ];

  return (
    <div className={`${styles.container} ${isLtr ? styles.containerLtr : ""}`}>
      <img
        src="/assets/unichat.svg"
        alt="UniChat Logo"
        className={styles.logo}
      />
      <div className={styles.content}>
        <h2
          className={styles.mainTitle}
          style={{ direction: `${isLtr ? "ltr" : "rtl"}` }}
        >
          {t("LpPage.unichat.mainTitle")}
        </h2>
        <div
          className={`${styles.subtitleWrapper} ${
            isLtr ? "direction-ltr text-ltr" : ""
          }`}
        >
          <h2 className={styles.subtitle}>{t("LpPage.unichat.subtitle")}</h2>
        </div>
        <div className={styles.benefitsContainer}>
          {benefits.map((benefit) => (
            <div
              key={benefit.id}
              className={`${styles.benefitItem} ${isLtr ? styles.ltr : ""}`}
            >
              <div className={styles.benefitImage}>
                <img
                  src={benefit.imgSrc}
                  alt="Logo"
                  className={styles.benefitLogo}
                />
              </div>
              <div className={styles.benefitContent}>
                <div
                  className={`${styles.benefitHeader} ${
                    isLtr ? styles.ltr : ""
                  }`}
                >
                  <h2
                    className={`${styles.benefitTitle} ${
                      isLtr ? styles.ltr : ""
                    }`}
                  >
                    {benefit.title}
                  </h2>
                  <h3
                    className={`${styles.benefitNumber} ${
                      isLtr ? styles.ltr : ""
                    }`}
                  >
                    .{benefit.id}
                  </h3>
                </div>
                <p
                  className={`${styles.benefitDescription} ${
                    isLtr ? styles.ltr : ""
                  }`}
                >
                  {benefit.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div>
          <h2 className={`${styles.bonusTitle} ${isLtr ? styles.ltr : ""}`}>
            {t("LpPage.unichat.bonusTitle")}
          </h2>
          <div className={styles.bonusContainer}>
            {benefitsbouns.map((benefitb) => (
              <div
                key={benefitb.id}
                className={`${styles.bonusItem} ${isLtr ? styles.ltr : ""}`}
              >
                <div
                  className={`${styles.bonusHeader} ${isLtr ? styles.ltr : ""}`}
                >
                  <div className={`${styles.bonusImageWrapper}`}>
                    <img
                      src={benefitb.imgSrc}
                      alt="Logo"
                      className={styles.bonusLogo}
                    />
                  </div>
                  <h2
                    className={`${styles.bonusItemTitle} ${
                      isLtr ? styles.ltr : ""
                    }`}
                  >
                    {benefitb.title}
                  </h2>
                </div>
                <p
                  className={`${styles.bonusDescription} ${
                    isLtr ? styles.ltr : ""
                  }`}
                >
                  {benefitb.description}
                </p>
              </div>
            ))}
          </div>
        </div>
        <p className={styles.finalDescription}>
          {t("LpPage.unichat.finalDescription")}
        </p>
        <button
          className={`${styles.ctaButton} ${isLtr ? styles.ltr : ""}`}
          onClick={openModalFunction}
          style={{ direction: `${isLtr ? "ltr" : "rtl"}` }}
        >
          {t("LpPage.unichat.ctaButton")}
        </button>
      </div>
    </div>
  );
};

export default Unichat;
