import React, { useState } from "react";
// Styles
import Styles from "./PlanCardsSection.module.scss";
import "../../utils/rootStyles.module.scss";
import PricingPlanCards, {
  PricingPlanCard,
} from "../../cmps/PricingPlanCard/PricingPlanCards.cmp";
import { getPricingCardsData } from "../../utils/HardCodedData";
import PricingToggleSwitch from "../../cmps/PricingToggleSwitch/PricingToggleSwitch.cmp";
import { openBillingModal } from "../../store/atoms/pricingPlanAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import BillindModal from "../../cmps/BillingModal/BillindModal.cmp";
import { useTranslation } from "react-i18next";
import {
  languageModel,
  redirectedToThankYouPage,
} from "../../store/atoms/generalAtoms";
import { extractUserData } from "../../utils/utils";
import { useLocation } from "react-router-dom";

export default function PlanCardsSection({
  startFree,
  toggleButtonNegative,
  startFreeFunction,
}: any) {
  const [pricingPlanCards, setPricingPlanCards] = useState<PricingPlanCard[]>(
    getPricingCardsData()
  );
  // const [openBillingModalState, setOpenBillingModalState] =
  //   useRecoilState(openBillingModal);
  const location = useLocation();
  const [t, i18n] = useTranslation();
  const recoilLangModel = useRecoilValue(languageModel);
  const { userPlan } = extractUserData.extractUrlUserPlan();
  const chatPricing = useRecoilValue<boolean>(redirectedToThankYouPage);

  return (
    <div id="pricing" className={Styles.PlanCardsContainer}>
      {!chatPricing && (
        <p>
          <span>{t("pricingCardsData.pricingCardPSpan")}</span>
          {/* <span>all Business</span> */}
        </p>
      )}
      {/* <PricingToggleSwitch negative={toggleButtonNegative} /> */}
      <div
        className={`${Styles.PricingPlanCardsDiv} ${
          recoilLangModel.slice(0, 2).includes("he")
            ? Styles.HebPlanCardsDirection
            : ""
        }`}
      >
        {pricingPlanCards &&
          pricingPlanCards
            // .slice(chatPricing ? 1 : 0)
            .map((pricingPlan: PricingPlanCard, index: number) => {
              const key = `${pricingPlan.planType}_${location.pathname}${index}`;
              // console.log("🚀 ~ file: PlanCardsSection.cmp.tsx:40 ~ {pricingPlanCards&&pricingPlanCards.map ~ key:", key)
              return (
                // <div key={`${pricingPlan.planType}_${location}${index}`}>
                <PricingPlanCards
                  key={key}
                  planType={pricingPlan.planType}
                  pricing={pricingPlan.pricing}
                  userPlan={userPlan}
                  startFreeFunction={
                    pricingPlan.planType === "free" ? startFreeFunction : null
                  }
                  startFree={startFree}
                  // setOpenBillingModalState={setOpenBillingModalState}
                />
                // </div>
              );
            })}
      </div>
      {/* {openBillingModalState && <BillindModal />} */}
    </div>
  );
}
