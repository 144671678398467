import React from 'react'
import Styles from './ExampleImgCard.module.scss'
import { languageModel } from '../../store/atoms/generalAtoms';
import { useRecoilValue } from 'recoil';

export interface ExampleImgCardType {
    img: string;
    title: string;
    description: string;
}

export default function ExampleImgCard({ img, title, description }: ExampleImgCardType) {
    const recoilLangModel = useRecoilValue(languageModel)
    return (
        <div className={`${Styles.ExampleImgCardMainContainer} ${recoilLangModel.toLocaleLowerCase().includes('he') ? Styles.HebClass : ""}`}>
            <img src={img} alt="" />
            <span>{title}</span>
            <p>{description}</p>
        </div>
    )
}
