import React, { useMemo } from "react";
import styles from "./LpFigureCards.module.scss";
import { useTranslation } from "react-i18next";
import FiguresCards from "../../FiguresCard/FiguresCard.cmp";
import { getFigureCardsData } from "../../../utils/HardCodedData";

const LpFigureCards = () => {
  const figuresData = useMemo(() => getFigureCardsData(), []);
  const { t } = useTranslation();

  return (
    <div id="about" className={styles.ForthRowFiguresContainer}>
      <p>{t("homePageFourthSection.p")}</p>
      <div className={styles.FiguresContainer}>
        {figuresData.map((figure: any) => {
          return (
            <div key={`${figure.data}_${Math.random() * 50}`}>
              <FiguresCards
                asset={figure.asset}
                number={figure.number}
                data={figure.data}
                symbol={figure.symbol}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LpFigureCards;
