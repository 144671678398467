import React, { useEffect, useMemo, useState } from "react";
import Styles from "./PricingPlanCards.module.scss";
// Util imports
// import { starIMG } from "../../utils/assetsImports";
import { WA_LINK, chatLink } from "../../utils/constant";
import { extractUserData, storage } from "../../utils/utils";
// Recoil imports
import { useRecoilState, useRecoilValue } from "recoil";
import {
  toggleButtonStatus,
  // openBillingModal,
} from "../../store/atoms/pricingPlanAtom";
// Cmps
import BillindModal from "../BillingModal/BillindModal.cmp";
// Mui
// import CheckIcon from '@mui/icons-material/Check';
// import { Alert } from "@mui/material";
// import StartFreeButton from "../StartFreeButton/StartFreeButton.cmp";
// Recoil
import {
  clientPhoneNumber,
  openLetsChatModal,
} from "../../store/atoms/contactUsAtom";
import { useTranslation } from "react-i18next";
import { languageModel } from "../../store/atoms/generalAtoms";
import {
  PricingCardsOptions,
  pricingCardsOptions,
} from "../../utils/HardCodedData";
import { useLocation } from "react-router-dom";
// import { Visibility } from "@mui/icons-material";

type PlanType = "free" | "basic" | "premium" | "pro";
export interface PricingPlanCard {
  planType: PlanType;
  pricing: number;
  userPlan?: string | null;
  startFreeFunction?: any;
  startFree?: boolean;
}

interface PricingPlanCardsProps extends PricingPlanCard {
  // setOpenBillingModalState: (open: boolean) => void;
}

const BillingPlans = {
  free: "",
  basic: "BSIC799",
  premium: "PRIM1499",
  pro: "PRO1999",
};

const ILPricing = {
  free: 0,
  basic: 29.6,
  premium: 49.9,
  pro: 74.99,
};

const user_email = storage.loadFromStorage("userEmail");

export default function PricingPlanCards({
  planType,
  pricing,
  userPlan,
  startFreeFunction,
  startFree,
}: // setOpenBillingModalState,
PricingPlanCardsProps) {
  const [t, i18n] = useTranslation();

  const togglePlanState = useRecoilValue(toggleButtonStatus);
  const [openChatModal, setOpenChatModal] = useRecoilState(openLetsChatModal);
  const recoilLangModel = useRecoilValue(languageModel);
  // const starsArray = Array(numberOfStars).fill(starIMG);
  const [planPricing, setPlanPricing] = useState<string>(
    // Math.floor(pricing * 0.8)
    !recoilLangModel.toLocaleLowerCase().includes("he")
      ? String(pricing)
      : ILPricing[planType].toFixed(2)
  );
  const [hoverStyles, setHoverStyles] = useState<any>({});
  const [chosenBillingPlan, setChosenBillingPlan] = useState<string>("");
  const userPhoneParam = useRecoilValue(clientPhoneNumber);
  const [openBillingModal, setOpenBillingModal] = useState<boolean>(false);
  const [planModelList, setPlanModelList] =
    useState<PricingCardsOptions>(pricingCardsOptions);
  const user_id = storage.loadFromStorage("userId");
  const pathname = useLocation().pathname;

  const currencySymbol = useMemo(() => {
    const lang = i18n.language.toLocaleLowerCase().split("-")[1];
    // console.log(
    //   "🚀 ~ file: PricingPlanCards.cmp.tsx:91 ~ currencySymbol ~ lang:",
    //   lang
    // );
    switch (lang) {
      case "il":
        return "₪";
      case "gb":
      case "tr":
      case "es":
      case "de":
      case "br":
      case "us":
        return "$";
      // case "gb":
      //   return "£";
      // case "tr":
      //   return "₺";
      // case "es":
      // case "de":
      //   return "€";
      // case "br":
      //   return "R$";

      default:
        return "$";
    }
  }, [i18n.language]);

  const colorByType: string =
    planType === "free"
      ? "#10B9AA"
      : planType === "basic"
      ? "#6358FF"
      : planType === "premium"
      ? "#ffb833"
      : "#FF58B2";
  const hoverEffect = {
    planContainer: { boxShadow: `8px 7px 0px 0px ${colorByType}` },
    planTypeHeader: { color: `${colorByType}` },
    buttonColor: { backgroundColor: `${colorByType}` },
  };

  const onclickOpenModal = (annualBilling: boolean = false) => {
    const currency = !recoilLangModel.toLocaleLowerCase().includes("he")
      ? "USD"
      : "ILS";
    const price = !recoilLangModel.toLocaleLowerCase().includes("he")
      ? String(pricing)
      : ILPricing[planType].toFixed(2);

    const dataLayerPlanData = {
      event: "add_to_cart",
      user_id: user_id,
      user_email: user_email,
      // user_id: userPhoneParam.replace(/^\+/, ""),
      currency: currency,
      value: price,
      item_name: planType,
      item_id: BillingPlans[planType],
    };

    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push(dataLayerPlanData);
    (window as any).fbq("track", "AddToCart");
    // sendEvent('add_to_cart', planType, currency, price);
    // console.log("🚀 ~ file: PricingPlanCards.cmp.tsx:112 ~ onclickOpenModal ~ (window as any).dataLayer:", (window as any).dataLayer)
    storage.saveToSessionStorage("dataLayerCheckOutData", dataLayerPlanData);

    if (planType !== "free") {
      chooseBillingPlan(annualBilling);
      setOpenBillingModal(true);
    } else {
      // setOpenChatModal(true);
      // (window as any).dataLayer.push({
      //   event: "start_chat",
      //   user_id,
      //   user_email
      // })
      if (startFreeFunction) {
        startFreeFunction();
      } else {
        window.open(chatLink, "_blank");
      }
    }
  };

  useEffect(() => {
    if (planType === "premium") {
      setHoverStyles(hoverEffect);
    }
  }, []);

  // useEffect(() => {
  //   togglePlanState
  //     ? setPlanPricing(pricing)
  //     : setPlanPricing(Math.floor(pricing * 0.8));
  // }, [togglePlanState]);

  const chooseBillingPlan = (annualBilling: boolean) => {
    // console.log("🚀 ~ file: PricingPlanCards.cmp.tsx:60 ~ chooseBillingPlan ~ annualBilling:", annualBilling, togglePlanState)
    // setChosenBillingPlan(BillingPlans[`${planType}`])

    storage.saveToSessionStorage("billingPlanParams", {
      planId: BillingPlans[`${planType}`],
      yearly: !annualBilling ? togglePlanState : annualBilling,
    });
  };

  return (
    <>
      <div
        className={Styles.PricingPlanCardsContainer}
        onMouseEnter={() => setHoverStyles(hoverEffect)}
        onMouseLeave={() => setHoverStyles({})}
        style={{
          ...hoverStyles.planContainer,
          textAlign: recoilLangModel.toLocaleLowerCase().includes("he")
            ? "right"
            : "left",
        }}
      >
        <span
          className={`${Styles.PlanTypeContainer} ${
            planType === "free"
              ? Styles.FreeCard
              : planType === "basic"
              ? Styles.BasicCard
              : planType === "premium"
              ? Styles.MediumCard
              : Styles.PermiumCard
          }`}
          style={{
            direction: recoilLangModel.toLocaleLowerCase().includes("he")
              ? "rtl"
              : "ltr",
          }}
        >
          <span style={hoverStyles.planTypeHeader}>
            {t(`pricingCardsData.${planType}.planType`)}
          </span>
          {/* <span>
          {starsArray.map((star: any) => {
            return (
              <img
                key={`${planType}_${Math.random() * 50}`}
                src={star}
                alt="Start image"
                />
                );
              })}
            </span> */}
        </span>
        <span
          className={`${Styles.PricingPlanCardPriceNumber} ${
            recoilLangModel.toLocaleLowerCase().includes("he")
              ? Styles.PricingPlanCardPriceNumber_RTL
              : ""
          }`}
        >
          <span>
            <p
            // style={{
            //   direction: i18n.language.toLocaleLowerCase().includes("he")
            //     ? "rtl"
            //     : "ltr",
            // }}
            >
              <span
                style={{
                  fontSize: "20px",
                  color: planType.includes("pro") ? "#FF58B2" : "",
                }}
              >{`${t(`pricingCardsData.${planType}.numberOfCredits`)} `}</span>
              {!planType.includes("pro") && (
                <span style={{ fontSize: "20px" }}>
                  {t(`pricingCardsData.${planType}.credits`)}
                </span>
              )}
            </p>
            <span className={Styles.priceNumber}>
              {`${
                currencySymbol
                // recoilLangModel.toLocaleLowerCase().includes("he") ? "₪" : "$"
              }${t(`pricingCardsData.${planType}.pricing`)}`}
            </span>
            {`/${
              !togglePlanState
                ? t("pricingCardsData.monthly")
                : t("pricingCardsData.yearly")
            }`}
          </span>
          <p>{t(`pricingCardsData.${planType}.description`)}</p>
          <a
            className={Styles.fairUseLink}
            style={{
              visibility: `${planType.includes("pro") ? "visible" : "hidden"}`,
            }}
            href={`https://lp.unichat.one/terms-of-use?w=Fair Use Policy`}
          >
            <span>* {t("pricingCardsData.fairUse")}</span>
          </a>
        </span>
        <div
          className={Styles.buttonAndPlanDataContainer}
          style={{
            direction: recoilLangModel.toLocaleLowerCase().includes("he")
              ? "rtl"
              : "ltr",
          }}
        >
          <div className={Styles.PricingPlanButtonAndSpan}>
            {/* <StartFreeButton title="Start For free" /> */}
            <button
              style={hoverStyles.buttonColor}
              className={`${Styles.PricingPlanTryFreeButton} ${
                planType === "free"
                  ? Styles.FreeButton
                  : planType === "basic"
                  ? Styles.BasicButton
                  : planType === "premium"
                  ? Styles.MediumButton
                  : Styles.PermiumButton
              }`}
              onClick={() => onclickOpenModal()}
            >
              {userPlan === planType
                ? t("pricingCardsData.active")
                : !startFree
                ? t("pricingCardsData.buttonTitle")
                : t("pricingCardsData.start")}
            </button>
            {/* <span onClick={() => onclickOpenModal(true)}>
              Save with annual billing (20% off) →
            </span> */}
          </div>
          {/* {planType !== "free" ? <p >{t(`pricingCardsData.basic.modelsIncluded`)}</p> : <p style={{ height: "1.5em" }}></p>} */}
          <ul>
            {planModelList[planType] &&
              planModelList[planType].length &&
              planModelList[planType].map((planOption) => {
                return (
                  <div>
                    <li>{t(planOption.model.value)}</li>
                    <li
                      style={
                        recoilLangModel.toLocaleLowerCase().includes("he")
                          ? { marginRight: "1rem" }
                          : {}
                      }
                    >
                      {t(planOption.model.credits)}
                    </li>
                  </div>
                );
              })}
            {/* {planType !== "free" && planType !== "basic" && (<>
              <div>
                <li>
                  {t(`pricingCardsData.${planType}.options.first`)}
                </li>
                <li>
                  {t("pricingCardsData.pricingCardsTokenCount.GPT4")}
                </li>
              </div>
            </>)
            }
            {planType !== "free" && planType !== "basic" && (<>
              <div>
                <li
                // className={
                //   planType === "basic" || planType === "free"
                //     ? Styles.NotIncluded
                //     : ""
                // }
                >
                  {t(`pricingCardsData.${planType}.options.second`)}
                </li>
                <li>
                  {t("pricingCardsData.pricingCardsTokenCount.Claude2")}
                </li>
              </div>
            </>)
            }
            <div>
              <li>
                {planType === "free"
                  ? t("pricingCardsData.free.options.third")
                  : t(`pricingCardsData.${planType}.options.third`)}
              </li>
              <li>
                {t("pricingCardsData.pricingCardsTokenCount.GPT3.5")}
              </li>
            </div>
            <div>
              <li
              // className={
              //   planType === "basic" || planType === "free"
              //     ? Styles.NotIncluded
              //     : ""
              // }
              >
                {t(`pricingCardsData.${planType}.options.fourth`)}
              </li>
              <li>
                {t("pricingCardsData.pricingCardsTokenCount.StableDiffusion")}
              </li>
            </div>
            {planType !== "free" && (<>
              <div>
                <li
                >
                  {t(`pricingCardsData.${planType}.options.fifth`)}
                </li>
                <li>
                  {t("pricingCardsData.pricingCardsTokenCount.DallE2")}
                </li>
              </div>
            </>)
            }
            {planType !== "free" && (<>
              <div>
                <li
                >
                  {t(`pricingCardsData.${planType}.options.sixth`)}
                </li>
                <li>
                  {t("pricingCardsData.pricingCardsTokenCount.Midjourney")}
                </li>
              </div>
            </>)
            }
            {planType !== "free" && planType !== "basic" && planType !== "premium" && <div>
              <li>{t(`pricingCardsData.${planType}.options.seventh`)}</li>
            </div>} */}
          </ul>
        </div>
      </div>
      {openBillingModal && (
        <BillindModal
          setOpenBillingModal={setOpenBillingModal}
          isWhatsApp={pathname
            .toLocaleLowerCase()
            .includes("/whatsappfreetrial")}
        />
      )}
    </>
  );
}
