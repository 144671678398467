import React, { useEffect, useState } from "react";
import Styles from "./LoaderSuccErr.module.scss";
import "../../rootStyles.module.scss";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import i18n from "../../i18n";

interface loaderProps {
  isLoading: boolean;
  responseStatus?: boolean;
  afterBackgroundColor?: string;
  loaderStyles?: any;
}

export default function LoaderSuccErr({
  isLoading,
  responseStatus,
  afterBackgroundColor,
  loaderStyles,
}: loaderProps) {
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [responseSuccess, setResponseSuccess] = useState<boolean>();

  const spinnerStyle: React.CSSProperties & { [key: string]: string } = {
    display: isLoading ? "block" : "none",
    "--after-color": afterBackgroundColor!,
  };

  useEffect(() => {
    if (responseStatus !== undefined) {
      setResponseSuccess(responseStatus!);
      // console.log(isLoading, responseStatus)
      setShowIcon(true);
    }
  }, [responseStatus]);
  return (
    <div
      className={` ${Styles.spinner} ${showIcon ? Styles.fadeOut : ""}`}
      style={{
        ...loaderStyles,
        ...spinnerStyle,
        direction: i18n.language.toLocaleLowerCase().includes("he")
          ? "rtl"
          : "ltr",
      }}
    >
      {isLoading && responseSuccess && (
        <CheckRoundedIcon
          style={{ visibility: showIcon ? "visible" : "hidden" }}
          classes={{ root: `${Styles.statusIconStylesShown}` }}
          color="success"
          fontSize="large"
        />
      )}
      {isLoading && responseSuccess === false && (
        <CloseRoundedIcon
          style={{ visibility: showIcon ? "visible" : "hidden" }}
          color="error"
          fontSize="large"
          classes={{ root: `${Styles.statusIconStylesShown}` }}
        />
      )}
    </div>
  );
}
