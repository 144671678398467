import React, { useState, useEffect, useMemo } from "react";
import { m, AnimatePresence } from "framer-motion";
import styles from "./SwitchWords.module.scss";
import { LazyMotionWrapper } from "../../utils/framer";

interface SwitchWordsProps {
  words: string[];
  interval?: number;
}

const SwitchWords: React.FC<SwitchWordsProps> = ({
  words,
  interval = 3000,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    console.log(
      "Longest word length:",
      Math.max(...words.map((word) => word.length))
    );
  }, [words]);

  const longestWordLength = useMemo(() => {
    return Math.max(...words.map((word) => word.length));
  }, [words]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, interval);

    return () => clearInterval(timer);
  }, [words, interval]);

  return (
    <div
      className={styles.switchWordsContainer}
      style={{ width: `${longestWordLength - 2.5}ch` }}
    >
      <LazyMotionWrapper>
        <AnimatePresence mode="wait">
          <m.span
            key={currentIndex}
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "-100%", opacity: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className={styles.word}
          >
            {words[currentIndex]}
          </m.span>
        </AnimatePresence>
      </LazyMotionWrapper>
    </div>
  );
};

export default SwitchWords;
