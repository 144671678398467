import { RxCross2 } from "react-icons/rx";
import { BiSolidCheckCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import styles from "./Suitable.module.scss";
import { useMemo } from "react";

interface SectionProps {
  openModalFunction: () => void;
}

interface SectionProps {
  openModalFunction: () => void;
}

const Suitable = ({ openModalFunction }: SectionProps) => {
  const { t, i18n } = useTranslation();
  const isLtr = useMemo(() => !i18n.language.includes("he"), [i18n.language]);

  return (
    <div
      className={`${styles.container} ${isLtr ? styles.ltr : ""} ${
        styles.suitableComponent
      }`}
    >
      <div className={styles.content}>
        {/* למי זה לא מתאים? */}
        <div className={`${styles.section} ${isLtr ? styles.ltr : ""}`}>
          <h2 className={`${styles.title} ${isLtr ? styles.ltr : ""}`}>
            <span>{t("LpPage.suitable.notSuitable.title.part1")}</span>{" "}
            <span className={styles.redText}>
              {t("LpPage.suitable.notSuitable.title.part2")}
            </span>
            ?
          </h2>
          <ul className={styles.list}>
            {[
              t("LpPage.suitable.notSuitable.item1"),
              t("LpPage.suitable.notSuitable.item2"),
              t("LpPage.suitable.notSuitable.item3"),
              t("LpPage.suitable.notSuitable.item4"),
            ].map((item, index) => (
              <li key={index} className={styles.listItem}>
                {item}
                <RxCross2 className={styles.crossIcon} />
              </li>
            ))}
          </ul>
        </div>

        {/* למי זה כן מתאים? */}
        <div
          className={`${styles.section} ${styles.sectionSecond} ${
            isLtr ? styles.ltr : ""
          }`}
        >
          <h2 className={`${styles.title} ${isLtr ? styles.ltr : ""}`}>
            <span>{t("LpPage.suitable.suitable.title.part1")}</span>{" "}
            <span className={styles.greenText}>
              {t("LpPage.suitable.suitable.title.part2")}
            </span>
            ?
          </h2>
          <ul className={styles.list}>
            {[
              t("LpPage.suitable.suitable.item1"),
              t("LpPage.suitable.suitable.item2"),
              t("LpPage.suitable.suitable.item3"),
              t("LpPage.suitable.suitable.item4"),
              t("LpPage.suitable.suitable.item5"),
            ].map((item, index) => (
              <li key={index} className={styles.listItem}>
                {item}
                <BiSolidCheckCircle className={styles.checkIcon} />
              </li>
            ))}
          </ul>
        </div>

        {/* BOX */}
        <h2 className={styles.boxTitle}>{t("LpPage.suitable.boxTitle")}</h2>
        <p className={`${styles.boxDescription} ${isLtr ? styles.ltr : ""}`}>
          {t("LpPage.suitable.boxDescription")}
        </p>
        <div className={`${styles.box} ${isLtr ? styles.ltr : ""}`}>
          <h2 className={styles.boxHeading}>
            {t("LpPage.suitable.boxHeading")}
          </h2>
          <p className={styles.boxParagraph}>
            {t("LpPage.suitable.boxParagraph")}
          </p>
          <div className={styles.buttonContainer}>
            <img
              src="/assets/arrow-down.svg"
              alt="Icons"
              className={styles.arrowIcon}
            />
            <div className={styles.gradientButton} onClick={openModalFunction}>
              <button className={styles.buttonText}>
                {t("LpPage.FreeTrialButton.button")}
              </button>
              <div className={styles.trialDays}>
                <h2 className={styles.trialNumber}>
                  {t("LpPage.FreeTrialButton.number")}
                </h2>
                <h4 className={styles.trialText}>
                  {t("LpPage.FreeTrialButton.days")}
                </h4>
              </div>
            </div>
            <img
              src="/assets/arrow-down.svg"
              alt="Icons"
              className={styles.arrowIcon}
            />
          </div>
          <button className={styles.startButton} onClick={openModalFunction}>
            {t("LpPage.suitable.startButton")}
          </button>
          <img
            src="/assets/cards.svg"
            alt="Cards"
            className={styles.cardsImage}
          />
        </div>
      </div>
    </div>
  );
};

export default Suitable;
