import React, { useEffect, useState } from "react";
import Styles from "./AboutAccordion.module.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { languageModel } from "../../store/atoms/generalAtoms";
import { useRecoilValue } from "recoil";

export interface UniAccordion {
  accordionHeader: string;
  accordionData: string;
}

interface AboutAccordionProps {
  accordionsArr: UniAccordion[];
}
export default function AboutAccordion({ accordionsArr }: AboutAccordionProps) {
  const [expanded, setExpanded] = useState<string | false>(`panel1`);
  const recoilLangModel = useRecoilValue(languageModel)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className={`${Styles.AboutAccordionMainContainer} ${recoilLangModel.toLocaleLowerCase().includes('he') ? Styles.HebClass : ""}`}>
      {accordionsArr.map((accordion: UniAccordion, index: number) => (
        <Accordion
          key={`panel${index}`}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{ maxWidth: "100%", backgroundColor: "#2E2E2E", color: "white" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <Typography sx={{ width: "100%", flexShrink: 0 }}>
              {accordion.accordionHeader}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "#1c1c1c",
              paddingTop: "1rem",
              borderTop: "1px solid #797979",
            }}
          >
            <Typography>{accordion.accordionData}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
