import React, { useState } from "react";
import Styles from "./StartFreeAndImg.module.scss";
import StartFreeButton from "../../cmps/StartFreeButton/StartFreeButton.cmp";
import { useNavigate, redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { languageModel } from "../../store/atoms/generalAtoms";
import { isValidPhoneNumber } from "react-phone-number-input/mobile";
import { extractUserData, getAllUTMData, storage } from "../../utils/utils";
import axios from "axios";
import { WA_LINK } from "../../utils/constant";

interface StartFreeProps {
  reverse?: boolean;
  imgStyle?: any;
  paragraphText: string;
  imgName: string[];
  column?: boolean;
  containerStyles?: any;
  buttonAndLabelStyles?: any;
  imagesContainerStyles?: any;
  negative?: boolean;
  buttonStyles?: any;
  buttonTitle?: string;
  onClickMethod?: () => void;
  twoButtons?: any;
  access_click?: boolean;
  phoneInput?: boolean;
  scrollToTop?: boolean;
}

export default function StartFreeAndImg({
  access_click,
  reverse,
  imgStyle,
  paragraphText,
  imgName,
  column,
  containerStyles,
  buttonAndLabelStyles,
  imagesContainerStyles,
  negative,
  buttonStyles,
  buttonTitle,
  onClickMethod,
  twoButtons,
  phoneInput,
  scrollToTop,
}: StartFreeProps) {
  const [t, i18n] = useTranslation();
  const recoilLangModel = useRecoilValue(languageModel);
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>("");
  const [validationError, setValidationError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  // console.log(twoButtons)
  const sendUserPhoneNumber = async () => {
    const isPhoneValid = isValidPhoneNumber(userPhoneNumber, "IL");
    // validation if the number is an actual phone number.
    if (!isPhoneValid) {
      setValidationError(true);
      return;
    } else {
      setValidationError(false);
    }
    // Gets the data from LocalStorage, sends it by url params to the api, and waits for the response to update the loader status icon.
    // console.log(phoneNumberRef.current)
    try {
      setIsLoading(true);
      const _fbp = extractUserData.getFBP();
      const clientFullData = storage.loadFromStorage("clientFullData");
      const utm = getAllUTMData(userPhoneNumber, "whatsApp");

      let params = {
        userPhone: userPhoneNumber,
        ...(_fbp ? { fbp: _fbp } : {}),
        ...(clientFullData.fbclid ? { fbclid: clientFullData.fbclid } : {}),
        ...(clientFullData.gaClientId
          ? { gaClientId: clientFullData.gaClientId }
          : {}),
        // ...(clientFullData.utm_source
        //     ? { utm_source: clientFullData.utm_source }
        //     : {}),
        // ...(clientFullData.utm_medium
        //     ? { utm_medium: clientFullData.utm_medium }
        //     : {}),
        // ...(clientFullData.utm_campaign
        //     ? { utm_campaign: clientFullData.utm_campaign }
        //     : {}),
        ...utm,
      };
      // console.log("🚀 ~ file: LetsChatModal.cmp.tsx:109 ~ sendDataToDB ~ params:", params)
      // console.log(params)
      const resp = await axios.get(
        `${process.env.REACT_APP_ENV}/api/chatbot/start-new-chat`,
        { params }
      );

      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: "sign_up",
        user_id: userPhoneNumber.replace(/^\+/, ""),
      });
      (window as any).fbq("track", "CompleteRegistration");
      // console.log(resp, params);

      // setRespStatus(true)
      // setRespStatus(false)

      window.location.href = WA_LINK;
      // setCloseLetsChatModal(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Determine the padding style based on the condition
  const dynamicPadding = recoilLangModel.includes("he")
    ? { paddingRight: "1rem" }
    : { paddingLeft: "1rem" };

  // Combine the dynamic padding with the other styles
  const spanStyle = {
    color: "red",
    ...dynamicPadding, // Spread the dynamic padding object here
  };
  return (
    <div
      id="midjourney"
      className={`${Styles.StartFreeAndImgContainer} ${
        reverse ? Styles.Reverse : null
      } ${column ? Styles.DirectionColumn : null} `}
      style={containerStyles}
    >
      <div
        className={`${Styles.StartFreeAndLabelDiv} ${
          recoilLangModel.toLocaleLowerCase().includes("he")
            ? Styles.HebClass
            : ""
        } ${twoButtons ? Styles.twoButtonsFlexColumn : ""}`}
        style={
          column
            ? { maxWidth: "40%", alignItems: "center", paddingLeft: "0" }
            : buttonAndLabelStyles
        }
      >
        <p style={column ? { textAlign: "center", width: "80%" } : undefined}>
          {paragraphText}
        </p>
        <div
          className={Styles.phoneInputAndFreeButton}
          style={{ alignItems: twoButtons ? "center" : "initial" }}
        >
          {phoneInput && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0 0 2rem 0",
              }}
            >
              <input
                className={`${Styles.whatsAppPhoneInput} ${
                  !recoilLangModel.includes("he")
                    ? Styles.WhatsAppPhoneInputEng
                    : ""
                }`}
                type="tel"
                title="phone number"
                placeholder={t("WhatsAppPage.PhoneInput.placeHolder")}
                value={userPhoneNumber}
                onChange={(e) => setUserPhoneNumber(e.target.value)}
                onKeyDown={(e) => {
                  e.key === "Enter" && sendUserPhoneNumber();
                }}
              />
              {validationError && (
                <span style={spanStyle}>
                  {t("WhatsAppPage.PhoneInput.error")}
                </span>
              )}
            </div>
          )}
          <div className={`${twoButtons ? Styles.twoButtonsContainer : ""}`}>
            <StartFreeButton
              scrollToTop={scrollToTop}
              access_click={access_click}
              title={
                buttonTitle
                  ? buttonTitle
                  : twoButtons
                  ? twoButtons.button1.title
                  : t("StartFreeButton.StartForFree")
              }
              negative={negative}
              customStyle={buttonStyles ? buttonStyles : { margin: "0 0 5rem" }}
              onClickMethod={
                twoButtons
                  ? twoButtons.button1.onClickMethod
                  : phoneInput
                  ? sendUserPhoneNumber
                  : onClickMethod
              }
              icon={twoButtons ? twoButtons.button1.icon : undefined}
              phoneInput={phoneInput ? true : false}
            />
            {twoButtons && (
              <StartFreeButton
                access_click={access_click}
                title={
                  buttonTitle
                    ? buttonTitle
                    : twoButtons
                    ? twoButtons.button2.title
                    : t("StartFreeButton.StartForFree")
                }
                negative={negative}
                customStyle={
                  buttonStyles ? buttonStyles : { margin: "0 0 5rem" }
                }
                onClickMethod={
                  twoButtons ? twoButtons.button2.onClickMethod : onClickMethod
                }
                icon={twoButtons ? twoButtons.button2.icon : undefined}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={Styles.ImagesContainer}
        style={imagesContainerStyles ? imagesContainerStyles : {}}
      >
        {imgName.map((img: string, index: number) => (
          <img
            // loading='lazy'
            key={`${img}_${index}`}
            src={img}
            style={imgStyle}
            alt=""
          />
        ))}
      </div>
    </div>
  );
}
