import React, { useEffect, useMemo, useState } from "react";
import LpVideoSection from "../../cmps/LandingPageCmps/LpVideoSection/LpVideoSection";
import styles from "./LandingPage.module.scss";
import LpFeatureHero from "../../cmps/LandingPageCmps/LpFeatureHero/LpFeatureHero";
import BillindModal from "../../cmps/BillingModal/BillindModal.cmp";
import { useTranslation } from "react-i18next";
import LpTextModelsSection from "../../cmps/LandingPageCmps/LpTextModelsSection/LpTextModelsSection.cmp";
import LpFigureCards from "../../cmps/LandingPageCmps/LpFigureCards/LpFigureCards.cmp";
import PlanCardsSection from "../../PageSections/PlanCardsSection/PlanCardsSection.cmp";
import FaqSection from "../../PageSections/FaqSection/FaqSection.cmp";
import UniContact from "../../cmps/UniContact/UniContact.cmp";
import { removeAccessibilityComponent } from "../../utils/utils";

const LandingPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [openBillingModal, setOpenBillingModal] = useState(false);

  useEffect(() => {
    removeAccessibilityComponent(i18n.language);
  }, [i18n.language]);

  const sections = useMemo(
    () => [
      {
        component: <LpVideoSection setOpenBillingModal={setOpenBillingModal} />,
        id: "video",
      },
      {
        component: <LpFeatureHero setOpenBillingModal={setOpenBillingModal} />,
        id: "features",
      },
      {
        component: <LpTextModelsSection />,
        id: "textModels",
      },
      {
        component: <LpFigureCards />,
        id: "about",
      },
      {
        component: <PlanCardsSection />,
        id: "pricing",
      },
      {
        component: <FaqSection />,
        id: "faq",
      },
      {
        component: <UniContact />,
        id: "contact",
      },
    ],
    []
  );

  return (
    <div
      className={styles.landingPage}
      dir={i18n.language.includes("he") ? "rtl" : "ltr"}
    >
      {sections.map((section, index) => (
        <section
          key={section.id}
          className={`${styles.section} ${
            index % 2 === 0 ? styles.evenSection : styles.oddSection
          }`}
          id={section.id}
        >
          {section.component}
        </section>
      ))}

      {openBillingModal && (
        <BillindModal setOpenBillingModal={setOpenBillingModal} isWhatsApp />
      )}
    </div>
  );
};

export default LandingPage;
