import React, { useEffect, useRef, useState } from 'react'
import styles from './PrivacyAndTOSPage.module.scss'
import { useTranslation } from 'react-i18next';

interface PrivacyAndTOSPageProps {
  data: { header: string, links: string[], content: Array<{ title: string, description: string }> }
}

const PrivacyAndTOSPage = ({ data }: PrivacyAndTOSPageProps) => {
  const { header, links, content } = data;
  const [chosenLink, setChosenLink] = useState<number>(0)
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { t, i18n } = useTranslation()
  useEffect(() => {
    // console.log("🚀 ~ PrivacyAndTOSPage ~ data:", data)
    let urlParams = new URLSearchParams(window.location.search);
    const w = urlParams.get('w')
    if (w) {
      const linkIndex: number = links.findIndex((link) => link.toLocaleLowerCase() === w.toLocaleLowerCase())
      if (linkIndex)
        scrollToTarget(w, linkIndex)
    }
  }, [])

  const scrollToTarget = (target: string, index: number) => {
    setChosenLink(index);
    const targetElement = document.getElementById(target);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center', // Ensures the top of the element is visible
      });
    }
  };

  return (
    <div className={`${styles.PrivacyAndTOSMainContainer} ${i18n.language.includes("he") ? styles.HebFont : ""}`} style={{ direction: i18n.language.includes("he") ? "rtl" : "ltr" }}>
      <div ref={contentRef}>
        <header className={styles.headerContainer}>
          <h1>{header}</h1>
        </header>
        <div className={styles.contentContainer}>
          <aside className={styles.contentLinks}>
            {links.map((link: string, index: number) => {
              return <a className={chosenLink === index ? styles.chosenLink : ""} key={link} href='#' onClick={() => scrollToTarget(link, index)}>{link}</a>
            })}
          </aside>
          <section className={styles.contentDescriptionContainer}>
            {content.slice(0, (header === "TERMS OF USE" || header === "תנאי שימוש") ? content.length - 1 : content.length).map((contentItem: { title: string, description: string }, index: number) => {
              return <>

                <div key={contentItem.title} id={contentItem.title}>
                  <span className={chosenLink === index ? styles.chosenLink : ""}>{contentItem.title}</span>
                  <pre>{`${contentItem.description}`}<a style={{ display: contentItem.title === "Contact Us" || contentItem.title === "יצירת קשר" ? "block" : "none" }} href='mailto: hello@unichat.one'>hello@unichat.one</a></pre>
                </div>
              </>
            })}

            {(header === "TERMS OF USE" || header === "תנאי שימוש") && <div key={content[content.length - 1].title} id={content[content.length - 1].title}>
              <span >{content[content.length - 1].title}</span>
              <pre>{`${content[content.length - 1].description.split(":")[0]}: `}<a title={content[content.length - 1].description.split("https")[1]} href={`https${content[content.length - 1].description.split("https")[1]}`}>{`https${content[content.length - 1].description.split("https")[1]}`}</a></pre>
            </div>}
          </section>
        </div>
      </div>
    </div>
  )
}

export default PrivacyAndTOSPage