import React, { useMemo, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import styles from "./FAQ.module.scss";

interface SectionProps {
  openModalFunction: () => void;
}

const FAQ = ({ openModalFunction }: SectionProps) => {
  const { t, i18n } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);
  const isLtr = useMemo(() => !i18n.language.includes("he"), [i18n.language]);

  const handleToggle = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const faqs = [
    {
      question: t("LpPage.faq.question1"),
      answer: t("LpPage.faq.answer1"),
    },
    {
      question: t("LpPage.faq.question2"),
      answer: t("LpPage.faq.answer2"),
    },
    {
      question: t("LpPage.faq.question3"),
      answer: t("LpPage.faq.answer3"),
    },
    {
      question: t("LpPage.faq.question4"),
      answer: t("LpPage.faq.answer4"),
    },
    {
      question: t("LpPage.faq.question5"),
      answer: t("LpPage.faq.answer5"),
    },
    {
      question: t("LpPage.faq.question6"),
      answer: t("LpPage.faq.answer6"),
    },
    {
      question: t("LpPage.faq.question7"),
      answer: t("LpPage.faq.answer7"),
    },
    {
      question: t("LpPage.faq.question8"),
      answer: t("LpPage.faq.answer8"),
    },
    {
      question: t("LpPage.faq.question9"),
      answer: t("LpPage.faq.answer9"),
    },
  ];

  return (
    <div className={`${styles.container} ${isLtr ? styles.ltr : ""}`}>
      <div className={styles.border}>
        <h2 className={styles.title}>{t("LpPage.faq.title")}</h2>
        {/* FAQ */}
        <div className={styles.faqContainer}>
          {faqs.map((faq, index: number) => (
            <div key={index} className={styles.faqItem}>
              <div
                className={styles.faqQuestion}
                onClick={() => handleToggle(index)}
              >
                <h2
                  className={`${styles.questionText} ${
                    isLtr ? styles.ltr : ""
                  }`}
                >
                  {faq.question}
                  <span>?</span>
                </h2>
                {expandedIndex === index ? (
                  <AiOutlineMinus className={styles.icon} />
                ) : (
                  <AiOutlinePlus className={styles.icon} />
                )}
              </div>
              <div
                className={`${styles.faqAnswer} ${
                  expandedIndex === index ? styles.expanded : styles.collapsed
                }`}
              >
                <p
                  className={`${styles.answerText} ${isLtr ? styles.ltr : ""}`}
                >
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>

        <p className={`${styles.description} ${isLtr ? styles.ltr : ""}`}>
          {t("LpPage.faq.description")}
        </p>
        <div className={styles.buttonContainer}>
          <img
            src="/assets/arrow-down.svg"
            alt="Icons"
            className={styles.arrowIcon}
          />
          <div className={styles.gradientButton} onClick={openModalFunction}>
            <button className={styles.buttonText}>
              {t("LpPage.FreeTrialButton.button")}
            </button>
            <div className={styles.trialDays}>
              <h2 className={styles.trialNumber}>
                {t("LpPage.FreeTrialButton.number")}
              </h2>
              <h4 className={styles.trialText}>
                {t("LpPage.FreeTrialButton.days")}
              </h4>
            </div>
          </div>
          <img
            src="/assets/arrow-down.svg"
            alt="Icons"
            className={styles.arrowIcon}
          />
        </div>
        <button className={styles.startButton} onClick={openModalFunction}>
          {t("LpPage.faq.startButton")}
        </button>
        <div className={styles.cardImage} />
        <p className={styles.disclaimer}>{t("LpPage.faq.disclaimer")}</p>
        <p className={styles.finalDescription}>
          {t("LpPage.faq.finalDescription")}
        </p>
      </div>
    </div>
  );
};

export default FAQ;
