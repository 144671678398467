import React from "react";
import styles from "./MoneyBackSection.module.scss";
import { moneyBackGuarantee } from "../../utils/assetsImports";

const MoneyBackSection: React.FC = () => {
  return (
    <div className={styles.moneyBackSection}>
      <div className={styles.container}>
        <div className={styles.imageWrapper}>
          <img src={moneyBackGuarantee} alt="30-Day Money Back Guarantee" />
        </div>
        <div className={styles.content}>
          <h2>30-Day Money Back Guarantee</h2>
          <p>
            We're confident you'll love our service. If you're not completely
            satisfied, we offer a full refund within the first 30 days of your
            purchase.
          </p>
          <ul>
            <li>No questions asked</li>
            <li>Easy and hassle-free process</li>
            <li>100% money-back guarantee</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MoneyBackSection;
