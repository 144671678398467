import React, { useEffect, useState } from 'react'
import styles from './Payment.module.scss'
import { getPurchasePlanLink } from '../../../utils/service'
const Payment = () => {
    const [userPlan, setUserPlan] = useState<string>("BSIC799")
    const [userPhone, setUserPhone] = useState<string>("")
    const [errorMessage, setShowErrorMessage] = useState<string>("")

    // useEffect(() => {
    //     console.log(userPhone, userPlan, errorMessage)
    // }, [userPhone, userPlan, errorMessage])

    const sendUserData = async () => {
        try {
            if (!!errorMessage.length) {
                setShowErrorMessage("")
            }
            const response = await getPurchasePlanLink(userPhone, userPlan);
            window.location.replace(response)

        } catch (err: any) {
            setShowErrorMessage(err.message)
        }
    }
    return (
        <div className={styles.paymentPageMainContainer}>
            <section className={styles.inputsContainer}>
                <div>
                    <label htmlFor="phone">Phone Number</label>
                    <input type="phone" name="phone" id="phone" title='phone' onChange={(e) => setUserPhone(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="plan">Plan ID</label>
                    <select name="plan" id="plan" onChange={(e) => setUserPlan(e.target.value)}>
                        <option value="BSIC799">Basic</option>
                        <option value="PRIM1499">Premium</option>
                        <option value="PROP1999">Pro</option>
                    </select>
                </div>
            </section>
            <button onClick={sendUserData}>Send</button>
            {errorMessage.length > 0 && <p style={{ color: "red" }}>{errorMessage}</p>}
        </div>
    )
}

export default Payment