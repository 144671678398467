import React, { useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./ChatPricingPage.module.scss";
import PlanCardsSection from "../../PageSections/PlanCardsSection/PlanCardsSection.cmp";
import FaqSection from "../../PageSections/FaqSection/FaqSection.cmp";
import UniContact from "../../cmps/UniContact/UniContact.cmp";
import { checkToken, extractUserData, storage } from "../../utils/utils";
import { useRecoilState, useSetRecoilState } from "recoil";
import { clientPhoneNumber } from "../../store/atoms/contactUsAtom";
import { openBillingModal } from "../../store/atoms/pricingPlanAtom";
import { redirectedToThankYouPage } from "../../store/atoms/generalAtoms";

export default function ChatPricingPage() {
  const navigate = useNavigate();
  const [userPhone, setUserPhone] = useRecoilState(clientPhoneNumber);
  const [billingModalState, setBillingModalState] =
    useRecoilState(openBillingModal);
  const setThankYouPage = useSetRecoilState<boolean>(redirectedToThankYouPage);

  useEffect(() => {
    setThankYouPage(true);
    const clientUrlParams = extractUserData.extractUrlParams();
    // Gets the data from the cookies
    const gaClientId = extractUserData.getGAClientId();
    const token = extractUserData.extractUrlToken();
    const { user_id } = extractUserData.extractUrlUserId();
    const { planId } = extractUserData.extractUrlPlanId();

    let urlUserPhone: string =
      extractUserData.extractUrlPhoneNumber().userPhone || "";
    if (urlUserPhone !== "") {
      // console.log(urlUserPhone)
      storage.saveToStorage("userPhone", `+${urlUserPhone}`);
      setUserPhone(`+${urlUserPhone}`);
    }

    if (token.token) {
      // console.log(token)
      storage.saveToStorage("userToken", token.token);
    }

    if (planId) {
      // console.log(user_id)
      storage.saveToSessionStorage("billingPlanParams", {
        planId: planId,
        yearly: false,
      });
    }
    if (user_id) {
      storage.saveToStorage("userId", user_id);
      // setBillingModalState(true)
    }
    // Saves all the data as an object to local storage.
    storage.saveToStorage("clientFullData", { ...clientUrlParams, gaClientId });

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const negishutElement = document.querySelector(
            ".accessibility_component.accessibility_div_wrap"
          ) as HTMLElement;
          if (negishutElement) {
            negishutElement.style.display = "none";
          }
        }
      });
    });

    // Observe the entire document. Adjust the target and options as necessary for your use case.
    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();

      // Your existing cleanup logic
      setThankYouPage(false);
      const negishutElement = document.querySelector(
        ".accessibility_component.accessibility_div_wrap"
      ) as HTMLElement;
      if (negishutElement) {
        negishutElement.style.display = "inherit";
      }
    };
  }, []);
  return (
    <div
      id="/pricing"
      className={`${Styles.chatPlanPricingPage} animate__animated animate__fadeIn`}
    >
      <PlanCardsSection toggleButtonNegative={true} />
    </div>
  );
}
