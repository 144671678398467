import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ForStarters.module.scss";

interface SectionProps {
  openModalFunction: () => void;
}

const ForStarters = ({ openModalFunction }: SectionProps) => {
  const { t, i18n } = useTranslation();
  const isLtr = useMemo(() => !i18n.language.includes("he"), [i18n.language]);

  return (
    <div className={`${styles.forStarters} ${isLtr ? styles.ltr : ""}`}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.mainHeading}>
            {t("LpPage.forStarters.mainHeading")}
          </h2>
          <p className={styles.paragraph}>
            {t("LpPage.forStarters.paragraph1")}
          </p>
          <h2 className={styles.subHeading}>
            {t("LpPage.forStarters.subHeading1")}
          </h2>
          <h2 className={styles.subHeading}>
            {t("LpPage.forStarters.subHeading2")}
          </h2>
          <h2 className={styles.subHeading}>
            {t("LpPage.forStarters.subHeading3")}
          </h2>
          <h2 className={`${styles.boldHeading} ${styles.subHeading}`}>
            {t("LpPage.forStarters.boldHeading")}
          </h2>
          <h3 className={styles.subHeading}>
            {t("LpPage.forStarters.subHeading4")}
          </h3>
          <p className={styles.paragraph}>
            {t("LpPage.forStarters.paragraph2")}
          </p>
          <h2 className={styles.finalHeading}>
            {t("LpPage.forStarters.finalHeading")}
          </h2>
        </div>
        <div className={styles.buttonWrapper}>
          <button className={styles.button} onClick={openModalFunction}>
            {t("LpPage.forStarters.buttonText")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForStarters;
