import React, { useState } from "react";
import styles from "./LpTextModels.module.scss";
import StartFreeAndImg from "../../../PageSections/StartFreeAndImg/StartFreeAndImg.cmp";
import { useTranslation } from "react-i18next";
import { buttonAndLabelStyles } from "../../../utils/constant";
import { gpt4Motion } from "../../../utils/assetsImports";
const LpTextModelsSection = () => {
  const [isBelowThreshold, setIsBelowThreshold] = useState(
    window.innerWidth < 1368
  );
  const { t, i18n } = useTranslation();

  return (
    <StartFreeAndImg
      // buttonAndLabelStyles={buttonAndLabelStyles[String(recoilLangModel).slice(0, 2) as keyof LanguageStyles]}
      containerStyles={{
        flexDirection: isBelowThreshold ? "column" : "",
        minHeight: "800px",
        maxWidth: "1240px",
      }}
      buttonAndLabelStyles={
        isBelowThreshold
          ? {
              ...(i18n.language.includes("he")
                ? buttonAndLabelStyles["he"]
                : buttonAndLabelStyles["en"]),
              flexDirection: "column",
            }
          : {
              ...(i18n.language.includes("he")
                ? buttonAndLabelStyles["he"]
                : buttonAndLabelStyles["en"]),
              paddingLeft: "0",
            }
      }
      imgStyle={{ height: "100%", width: "100%" }}
      paragraphText={t("GPT-4Section.headerParagarph")}
      imgName={[gpt4Motion]}
      // buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : { margin: "0 0 5rem" }}
      buttonStyles={!isBelowThreshold ? { margin: "0 0 0" } : {}}
    />
  );
};

export default LpTextModelsSection;
