import React from "react";
import Styles from "./UniFooter.module.scss";
import { instagramFooterSVG, threadsSVG, tiktokFooterSVG, twitterFooterSVG, facebookSVG } from '../../utils/assetsImports'
import { useTranslation } from "react-i18next";

export default function UniFooter() {
  const [t, in18x] = useTranslation()
  return (
    <div className={Styles.UniFooterContainer}>
      <span>{t('UniFooter.span')}</span>
      <span className={Styles.MediaRedirectionsSpan}><a href='https://www.facebook.com/Unichat.one'><img src={facebookSVG} alt="Instagram" /></a><a href='https://www.instagram.com/unichat.one/'><img src={instagramFooterSVG} alt="Instagram" /></a><a href="https://www.threads.net/@unichat.one"><img src={threadsSVG} alt="Threads" /></a><a href='https://www.tiktok.com/@unichat.one.bot'><img src={tiktokFooterSVG} alt="TikTok" /></a><a href='https://twitter.com/Unichatone'><img src={twitterFooterSVG} alt="Twitter" /></a></span>
    </div>
  );
}
