import React, { useCallback, useEffect, useRef, useState } from "react";
import Styles from "./MuiStepper.module.scss";
// Mui imports
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// Cmps imports
import StartFreeButton from "../../../cmps/StartFreeButton/StartFreeButton.cmp";
// Recoil Imports
import { useRecoilState, useRecoilValue } from "recoil";
import {
  openBillingModal,
  billingIframeUrl,
} from "../../../store/atoms/pricingPlanAtom";
import { clientPhoneNumber } from "../../../store/atoms/contactUsAtom";
// React phone input imports
import { isValidPhoneNumber } from "react-phone-number-input/mobile";
// Util imports
import axios from "axios";
import { extractUserData, storage } from "../../utils";
import LoaderSuccErr from "../LoaderSuccErr/LoaderSuccErr.cmp";
import { getURI } from "../../constant";
import { useTranslation } from "react-i18next";
import BillingIframe from "../../../cmps/BillingIframe/BillingIframe.cmp";
import InsertWhatsAppNumber from "../../../cmps/InsertWhatsAppNumber/InsertWhatsAppNumber.cmp";
import LoginPage from "../../../pages/LoginPage/LoginPage.cmp";
import { languageModel, freeTrial } from "../../../store/atoms/generalAtoms";
import WhatsappRegistration from "../../../cmps/WhatsappRegistration/WhatsappRegistration";

// const steps = ['Insert Whatsapp number', 'Confirm Payment'];

interface MuiStepperInterface {
  // stepsCmps: Array<any>;
  planId?: string;
  yearly?: string;
  setOpenBillingModal: React.Dispatch<React.SetStateAction<boolean>>;
  isWhatsApp?: boolean;
  isMoneyBackGuarantee?: boolean;
}

export default function MuiStepper({
  // stepsCmps,
  planId,
  yearly,
  setOpenBillingModal,
  isWhatsApp,
  isMoneyBackGuarantee,
}: MuiStepperInterface) {
  const [t, i18n] = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  // const [closeBillingModal, setCLoseBillingModal] =
  //     useRecoilState(openBillingModal);
  const [iFrameUrl, setIframeUrl] = useState("");
  const [validationError, setValidationError] = useState<any>({
    flag: false,
    errorTitle: t("BillingModal.MuiStepper.validationErrorTitle"),
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [respStatus, setRespStatus] = useState<boolean>();
  const userPhone = useRecoilValue(clientPhoneNumber);
  const token = storage.loadFromStorage("userToken");
  const userId = storage.loadFromStorage("userId");
  const userPhoneRef = useRef("");
  const [titles, setTitles] = useState<string[]>([
    !isWhatsApp
      ? t("BillingModal.MuiStepper.step1")
      : t("BillingModal.MuiStepper.step1WhatsApp"),
    t("BillingModal.MuiStepper.step2"),
  ]);
  const [titlesWithToken, setTitlesWithToken] = useState<string[]>([
    t("BillingModal.MuiStepper.step2"),
  ]);
  const [showIframeErrorMessage, setShowIframeErrorMessage] =
    useState<boolean>(false);
  const [registered, setRegistered] = useState<boolean>(false);
  const recoilLangModel = useRecoilValue(languageModel);
  const isFreeTrial = useRecoilValue(freeTrial);
  // console.log(userId)
  // the current component to render
  // const StepCmp: React.ComponentType<any> = stepsCmps[activeStep]?.cmp;
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      // Call sendDataToDB when Enter is pressed
      handleNext();
    }
  };
  // useEffect(() => {
  //     userPhoneRef.current = userPhone
  //     // console.log(userPhone)
  // }, [userPhone])
  useEffect(() => {
    // console.log(activeStep)
    if (activeStep === 1) {
      sendDataToBillingIframe();
    }
  }, [activeStep]);

  // useEffect that checks if the user landed on the pricing page with its phone number attached to the url.
  useEffect(() => {
    // if ((userPhoneRef.current && userPhoneRef.current !== "")) {
    //     if (sendDataValidate()) {
    //         // sends the user data to the server and gets the relevant iframe url.
    //         sendDataToBillingIframe();
    //         setActiveStep(1);
    //     }

    if (token && userId) {
      setActiveStep(1);
    }
    // }

    // Add the event listener when the component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  // useEffect(() => {
  // }, [token])

  // Checks if the phone number is valid
  const sendDataValidate = (): boolean => {
    // console.log("🚀 ~ file: MuiStepper.cmp.tsx:85 ~ sendDataValidate ~ userPhone:", userPhone)
    if (isValidPhoneNumber(userPhoneRef.current)) {
      if (validationError.flag) {
        setValidationError({ ...validationError, flag: false });
      }
      return true;
    }
    setValidationError({ ...validationError, flag: true });
    return false;
  };

  // const isStepOptional = (step: number) => {
  //     return step === 1;
  // };

  // const isStepSkipped = (step: number) => {
  //     return skipped.has(step);
  // };

  const setStep = (number: number) => {
    setActiveStep(activeStep + number);
  };
  const sendDataToBillingIframe = async (
    userPhoneFromParams: boolean = false
  ) => {
    // console.log(sendDataValidate(), userPhone);
    const begin_checkout = storage.loadFromSessionStorage(
      "dataLayerCheckOutData"
    );

    begin_checkout.event = "begin_checkout";
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push(begin_checkout);
    (window as any).fbq("track", "InitiateCheckout");
    try {
      // console.log(recoilLangModel.slice(0, 2))
      setIsLoading(true);
      const billingPlanParams =
        storage.loadFromSessionStorage("billingPlanParams");
      // console.log("🚀 ~ file: MuiStepper.cmp.tsx:76 ~ handleNext ~ billingPlanParams:", billingPlanParams)

      const params = {
        ...billingPlanParams,
        language: i18n.language,
        freeTrial: isFreeTrial,
      };
      if (userPhoneRef.current !== "") {
        params.userPhone = userPhoneRef.current;
      }
      if (userId) {
        // console.log("🚀 ~ file: MuiStepper.cmp.tsx:157 ~ sendDataToBillingIframe ~ userId:", userId)
        params.userId = userId;
      }
      // console.log("🚀 ~ file: MuiStepper.cmp.tsx:150 ~ sendDataToBillingIframe ~ params:", params)

      const uri = getURI();

      // console.log("🚀 ~ file: MuiStepper.cmp.tsx:160 ~ sendDataToBillingIframe ~ uri:", uri)
      const resp = await axios.get(`${uri}/order/plan/subscription`, {
        params,
      });
      // console.log("🚀 ~ file: MuiStepper.cmp.tsx:153 ~ sendDataToBillingIframe ~ resp:", resp)
      // const resp = { status: 200 }
      // console.log(resp);
      if (resp.status === 200 && Object.keys(resp.data).length > 0) {
        // console.log(resp.data)
        setIframeUrl(resp.data.iframeUrl);
        // console.log("🚀 ~ file: MuiStepper.cmp.tsx:109 ~ sendDataToBillingIframe ~ resp.data.iframeUrl:", resp.data.iframeUrl)
        setRespStatus(true);
        if (activeStep < 1) {
          setTimeout(() => {
            setActiveStep(activeStep + 1);
          }, 1000);
        }
        // if (!userPhoneFromParams) setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setRespStatus(false);
        setValidationError({
          flag: true,
          errorTitle: "An error occurred while sending data to the server",
        });
        return;
      }
    } catch (err: any) {
      console.log(err);
      if (err.request.status === 400) {
        // setShowIframeErrorMessage(true)
        setActiveStep(0);
        setShowIframeErrorMessage(false);
        // setTimeout(() => {
        // }, 2000)
      }
      const failed_request = { ...begin_checkout };
      failed_request.event = "registration_failed";
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push(failed_request);
      setRespStatus(false);
      setValidationError({
        flag: true,
        errorTitle: "Server error occurred, try again later",
      });
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleNext = async () => {
    // let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //     newSkipped = new Set(newSkipped.values());
    //     newSkipped.delete(activeStep);
    // }
    // if (activeStep === 0) {
    //     firstStepFunction!()
    // }
    if (activeStep === 0) {
      if (!sendDataValidate()) {
        return;
      }
      sendDataToBillingIframe();
    } else if (
      activeStep < token ? titlesWithToken.length - 1 : titles.length - 1
    ) {
      setActiveStep(activeStep + 1);
    } else {
      setOpenBillingModal(false);
    }
    // setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setIsLoading(false);
  };

  // const handleSkip = () => {
  //     if (!isStepOptional(activeStep)) {
  //         // You probably want to guard against something like this,
  //         // it should never occur unless someone's actively trying to break something.
  //         throw new Error("You can't skip a step that isn't optional.");
  //     }

  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //     setSkipped((prevSkipped) => {
  //         const newSkipped = new Set(prevSkipped.values());
  //         newSkipped.add(activeStep);
  //         return newSkipped;
  //     });
  // };

  const handleReset = () => {
    setActiveStep(0);
  };
  // console.log(StepCmp)
  return (
    <>
      {/* <Stepper
        activeStep={activeStep}
        sx={{
          height: "5%",
          width: "90%",
          margin: "2% auto 1%",
          justifyContent: token ? "center" : "",
          direction: recoilLangModel.includes("he") ? "rtl" : "ltr",
        }}
      >
        {token && !registered
          ? titlesWithToken.map((cmp, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              return (
                <Step key={cmp} {...stepProps}>
                  <StepLabel {...labelProps}>{cmp}</StepLabel>
                </Step>
              );
            })
          : titles.map((cmp, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              return (
                <Step key={cmp} {...stepProps}>
                  <StepLabel {...labelProps}>{cmp}</StepLabel>
                </Step>
              );
            })}
      </Stepper> */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          display: "flex",
          flexDirection: "column",
          // justifyContent: "flex-start",
          justifyContent: "center",
          alignItems: "center",
          height: `${activeStep > 0 ? "94%" : "100%"}`,
        }}
      >
        <React.Fragment>
          {activeStep === 0 && isWhatsApp ? (
            <WhatsappRegistration
              setStep={setStep}
              setRegistered={setRegistered}
              isMoneyBackGuarantee={isMoneyBackGuarantee}
            />
          ) : (
            activeStep === 0 && (
              <LoginPage setStep={setStep} setRegistered={setRegistered} />
            )
          )}
          {activeStep === 1 && (
            <>
              {/* <section className={Styles.whatsAppPageHeader}>
                {`${t("firstWeekFree")}`}
              </section>
              <section
                className={`${Styles.whatsAppPageHeader} ${Styles.redHeader}`}
              >
                {`${t("creditCardWillNotBeCharged")}`}
              </section> */}
              <BillingIframe
                iFrameUrl={iFrameUrl}
                // iFrameUrl={"http://localhost:3000/thank-you-page"}
                showIframeErrorMessage={showIframeErrorMessage}
              />
            </>
          )}
          {/* </div> */}
          {activeStep > 0 && !token && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "flex-start",
                mb: "1%",
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t("BillingModal.MuiStepper.backButton")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {/* {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                                </Button>
                        )}  */}
              {activeStep <
                (token ? titlesWithToken.length - 1 : titles.length - 1) && (
                <Button onClick={handleNext}>
                  {/* {activeStep === stepsCmps.length - 1 ? 'Finish' : 'Next'} */}
                  Next
                </Button>
              )}
            </Box>
          )}
        </React.Fragment>
      </Box>
    </>
  );
}
