import { atom } from "recoil";

// global state that stores the state of the plan toggle button
export const toggleButtonStatus = atom({
    key: "toggleButtonStatus",
    default: false
})

// global state that stores the openBillingModal state
export const openBillingModal = atom({
    key: "openBillingModal",
    default: false,
})

// global state that stores the payment iframe url that comes back from the server with user relevant data
export const billingIframeUrl = atom({
    key: "billingIframeUrl",
    default: ""
})