import React from 'react'
import styles from './ServiceDataCard.module.scss'
import { useRecoilValue } from 'recoil';
import { languageModel } from '../../store/atoms/generalAtoms';

export interface ServiceDataCardsInterface {
    imgSource: string;
    headerTitle: string;
    paragraphText: string;
}

const ServiceDataCard = ({ imgSource, headerTitle, paragraphText }: ServiceDataCardsInterface) => {
    const recoilLangModel = useRecoilValue(languageModel)
    return (
        <div className={styles.serviceDataCardContainer} style={recoilLangModel.includes('he') ? { direction: "rtl" } : {}}>
            <img className={styles.imgSource} src={imgSource} alt={headerTitle} />
            <span className={styles.headerTitle}>{headerTitle}</span>
            <p className={styles.paragraphText}>{paragraphText}</p>
        </div>
    )
}

export default ServiceDataCard