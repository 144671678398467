import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./LpVideoSection.module.scss";
import SwitchWords from "../../SwitchWords/SwitchWords";
import StartFreeButton from "../../StartFreeButton/StartFreeButton.cmp";
import Video from "./video.cmp";
import FeatureCard from "./feature-card.cmp";
import { LpVideoSectionData } from "../../../utils/HardCodedData";

const words = ["Amazing Videos", "Stunning Images", "Intelligent Text"];

interface LpVideoSectionProps {
  setOpenBillingModal: (open: boolean) => void;
}

const LpVideoSection = ({ setOpenBillingModal }: LpVideoSectionProps) => {
  const { i18n } = useTranslation();

  return (
    <div className={styles.lpVideoSection}>
      <div className={styles.content}>
        <header className={styles.header}>
          <span>
            {" "}
            Generate{" "}
            <span className={styles.purpleText}>
              <SwitchWords words={words} interval={3000} />
            </span>
            with
          </span>
          advanced AI tools AI tools
        </header>
        {/* <div className={styles.header}>
          <span>Your Ultimate AI Assistant</span>
          <span>for WhatsApp is Here</span>
        </div>
        <h2 className={styles.subHeader}>
          Generate{" "}
          <span className={styles.purpleText}>
            <SwitchWords words={words} interval={3000} />
          </span>{" "}
          with advanced AI tools
        </h2> */}
        <StartFreeButton
          title="START FREE"
          onClickMethod={() => setOpenBillingModal(true)}
          customStyle={{
            backgroundColor: "var(--brandPurple)",
            marginTop: "0",
          }}
        />
        <Video />
        {/* <div className={styles.features}>
          {LpVideoSectionData.featureCards.map((card, index) => (
            <FeatureCard
              key={index}
              title={card.title}
              description={card.description}
              icon={card.icon}
              index={index + 1}
            />
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default LpVideoSection;
